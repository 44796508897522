import React, { useContext, useEffect, useState } from "react";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { IconArrowIOS } from "../staticIcons/IconArrowIOS";
import { Option } from "./MerchantWebappStepOneCoin";

interface props {
  onSelect: () => void;
}

export const MerchantWebAppStepOneNetwork = ({ onSelect }: props) => {
  const [networks, setNetworks] = useState<Option[]>([]);
  const { selectedCoin, setSelectedNetwork, coinsListMap } =
    useContext(MerchantsContext);
  useEffect(() => {
    if (selectedCoin && coinsListMap) {
      const Coin = coinsListMap[selectedCoin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const optionsNetwork = Object.entries(networks).map(
          ([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.depositEnable,
          })
        );
        setNetworks(optionsNetwork);
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center mb-ios-22">
        <img
          alt="coinIcon"
          className="w-ios-80px me-ios-15px theme-light-show"
          src={toAbsoluteUrl("/media/webapp/network.svg")}
        />
        <img
          alt="coinIcon"
          className="w-ios-80px me-ios-15px theme-dark-show"
          src={toAbsoluteUrl("/media/webapp/network-dark.svg")}
        />
        <span className="fs-ios-34 fw-ios-700">Select network</span>
      </div>
      <p className="text-center fs-ios-17 mb-ios-46">
        Choose the network to pay the invoice
      </p>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p
          className="text-ios-light fs-ios-13 ml-ios-17 mb-0 text-uppercase"
          style={{ width: "360px" }}
        >
          Choose a network
        </p>
        <div
          className="bg-ios-light rounded-ios py-ios-12 pl-ios-16 mt-ios-9"
          style={{ width: "360px" }}
        >
          {networks.map((item, index) => (
            <div key={index}>
              <div
                className={`d-flex justify-content-between cursor-pointer ${
                  item.disable && "disable"
                }`}
                onClick={() => {
                  onSelect();
                  setSelectedNetwork(item.value);
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={toAbsoluteUrl(item.image)}
                    className="mr-ios-15"
                    width="30"
                    alt="coin"
                  />
                  <span className="fs-ios-17">{item.label}</span>
                </div>
                <div>
                  {item.disable && (
                    <span className="badge badge-light">Disabled</span>
                  )}
                  <IconArrowIOS />
                </div>
              </div>
              {index !== networks.length - 1 && (
                <div
                  className="separator separator-ios my-ios-10 ml-ios-42"
                  style={{ width: "calc(100% - 42px)" }}
                ></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
