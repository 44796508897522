import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const ERROR_LOG = `${API_URL}/core/error`;
const TransferCost = `${API_URL}/api/currencies`;
const BlogArticles = `https://api.oxapay.com/blog/feed`;
const SWAP_PAIRS = `${API_URL}/swap/pairs`;

export function logError(error: any) {
  return axios.post(ERROR_LOG, { error });
}

export function transferCost() {
  return axios.post(TransferCost);
}

export function blogArticles() {
  return axios.post(BlogArticles);
}

export function swapPairs() {
  return axios.post(SWAP_PAIRS);
}
