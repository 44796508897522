import React, { useContext, useEffect } from "react";
import { PaymentlinkContext } from "../../app/Core/PaymentLinkContext";
import { BlockConfirm } from "../BlockAnimate/BlockConfirm";
import { TextCopyWidget } from "../TextCopyWidget";

interface props {
  activeStep: number;
  setActiveIndex: (activeIndex: number) => void;
}

export const PaymentlinkStepThree = ({ activeStep, setActiveIndex }: props) => {
  const { paymentlinkSession } = useContext(PaymentlinkContext);
  useEffect(() => {
    Number(paymentlinkSession?.step) === 4 && setActiveIndex(3);
    // eslint-disable-next-line
  }, [paymentlinkSession?.step]);

  return (
    <>
      <div className="mt-15 mb-10">{activeStep >= 2 && <BlockConfirm />}</div>
      <div>
        <p className="fw-bolder text-dark fs-2 mb-1">
          Confirming on blockchain
        </p>
        <div className="mt-2 mb-15">
          <span className="badge badge-light-primary badge-square badge-lg px-3 py-3 me-2">
            {paymentlinkSession?.confirm} /{" "}
            {paymentlinkSession?.confirmRequired}
          </span>
          <span className="fs-4 fw-semibold text-primary">Confirms</span>
          <div>
            {paymentlinkSession?.id && (
              <p className=" rounded px-2 d-block py-1 fs-7 fw-semibold text-secondary-500 mb-0 mt-10">
                Transaction Track ID:{" "}
                <TextCopyWidget
                  text={`${paymentlinkSession.id}`}
                  tooltipText="Copy Track ID"
                  className="fs-5 mb-2 mt-7 fm-jetbrain"
                />
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
