import axios from "axios";
import { createContext, useState, ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { errorHandler } from "../services/errorHandler";
import { useRequest } from "../services/userHttpRequests";

interface donateSessionModel {
  backUrl: string;
  coin: string;
  confirm: string;
  confirmRequired: string;
  email: string;
  id: string;
  name: string;
  network: string;
  step: string;
  wallet: string;
}

interface Network {
  coin: string;
  slug: string;
  network: string;
  name: string;
  depositEnable: boolean;
  withdrawEnable: boolean;
  isMain: boolean;
}

interface NetworkList {
  [network: string]: Network;
}

export interface CoinsList {
  [symbol: string]: {
    symbol: string;
    name: string;
    status: boolean;
    slug: string;
    displayPrecision: string;
    networkList: NetworkList;
  };
}

export interface DonateContextModel {
  donateSession?: donateSessionModel;
  setDonateSession: (donateSession: donateSessionModel) => void;
  coinsListMap: CoinsList;
  setCoinsListMap: (_coinsList: CoinsList) => void;
}

export const DonateContext = createContext<DonateContextModel>({
  donateSession: undefined,
  setDonateSession: () => {},
  coinsListMap: {},
  setCoinsListMap: (_coinsListMap: CoinsList) => {},
});

interface DonateProviderProps {
  children: ReactNode;
}

const DonateProvider: React.FC<DonateProviderProps> = ({ children }) => {
  const [donateSession, setDonateSession] = useState<
    donateSessionModel | undefined
  >(undefined);
  const [coinsListMap, setCoinsListMap] = useState<CoinsList>({});

  const { fetchPayment } = useRequest();
  const { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await fetchPayment(
          id as string,
          "donate",
          donateSession?.id,
          "",
          { signal: controller.signal }
        );
        if (response.status === 200) {
          if (
            JSON.stringify(response.session) !== JSON.stringify(donateSession)
          ) {
            setDonateSession(response.session);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    const interval = setInterval(fetchData, 3000);

    if (
      !(
        donateSession?.id &&
        donateSession.wallet &&
        Number(donateSession.step) < 4
      )
    ) {
      clearInterval(interval); // Clean up the interval
    }

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval
    };
    // eslint-disable-next-line
  }, [donateSession]);

  return (
    <DonateContext.Provider
      value={{
        donateSession,
        setDonateSession,
        coinsListMap,
        setCoinsListMap,
      }}
    >
      {children}
    </DonateContext.Provider>
  );
};

export default DonateProvider;
