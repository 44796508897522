import React from "react";

export const PostSkeleton = () => {
  return (
    <div className="col-12 col-md-4">
      <div className="card-2 rounded-35px overflow-hidden p-3 mb-5">
        <div className="h-300px w-100 bg-secondary rounded-25px mr-ios-15 shine"></div>
        <div className="px-9 pt-10 pb-10">
          <div className="h-40px w-100 bg-secondary rounded mr-ios-15 shine mb-4"></div>
          <div className="h-20px w-100 bg-secondary rounded mr-ios-15 shine mb-2"></div>
          <div className="h-20px w-100 bg-secondary rounded mr-ios-15 shine mb-2"></div>
          <div className="h-20px w-100 bg-secondary rounded mr-ios-15 shine mb-2"></div>
          <div className="h-20px w-100 bg-secondary rounded mr-ios-15 shine mb-2"></div>
          <div className="h-20px w-50 bg-secondary rounded mr-ios-15 shine"></div>
          <div className="row mt-10 align-items-center">
            <div className="col-12 col-md-6">
              <div className="h-30px w-150px bg-secondary rounded mr-ios-15 shine"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
