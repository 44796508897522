import React from "react";
import { signIn, signUp } from "../../app/services/links";
import { AbsoluteCircle } from "./AbsoluteCircle";
import { AppButton } from "./AppButton";
import TypingEffect from "./TypingEffect";

export const MainSection = () => {
  return (
    <div className="px-5">
      <div className="p-10 d-flex flex-column justify-content-center align-items-center multicolor-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
        <AbsoluteCircle position="top-left" />
        <AbsoluteCircle position="bottom-right" />
        <div className="z-index-1">
          <p className="fs-1 text-center text-secondary m-0 mb-3 d-none d-lg-block">
            OxaPay, a crypto payment gateway that makes it easy to
          </p>
          <h1 className="m-0 text-center text-white large-title fw-bold mb-5">
            Accept Crypto Payment
            <br />
            In Your <TypingEffect textArray={["Business", "Website", "Apps"]} />
          </h1>
          <p className="fs-md-1 fs-5 text-center text-secondary m-0">
            From 0.4% Fees, 24/7 Support, No hidden fee, No KYC/KYB
          </p>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-20">
            <a href={signUp}>
              <AppButton className="btn-primary px-10 m-2 py-5">
                Try It Now
              </AppButton>
            </a>
            <a className="d-inline-block" href={signIn}>
              <AppButton className="btn-black px-14 m-2 py-5">
                Sign In
              </AppButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
