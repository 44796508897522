import React from "react";
import { Link } from "react-router-dom";
import { SliderLogo } from "../pages/SliderLogo";

interface props {
  title?: string;
}
export const SupportedCoins = ({
  title = "Supported coins and blockchains",
}: props) => {
  return (
    <div className="p-5">
      <div className="gray-gradient justify-content-center align-items-center w-100 rounded-35px overflow-hidden">
        <div className="row">
          <div className="col-12 col-md-6">
            <SliderLogo reverse />
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-start align-items-center">
            <div className="d-flex justfiy-content-center flex-column align-items-start px-7 py-12">
              <h1 className="fs-4x fw-bold">{title}</h1>
              <Link
                className="ihover fs-3 text-dark d-flex align-items-center"
                to="/currencies"
              >
                <span className="me-3">
                  Check supported coins and blockchains
                </span>
                <i className="fa-solid fa-arrow-right fs-2 text-dark"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
