import axios from "axios";
import { createContext, useState, ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { errorHandler } from "../services/errorHandler";
import { useRequest } from "../services/userHttpRequests";

interface paymentlinkSessionModel {
  amount: string;
  backUrl: string;
  coin: string;
  confirm: string;
  confirmRequired: string;
  email: string;
  id: string;
  name: string;
  network: string;
  step: string;
  wallet: string;
  duration: string;
  expireAt: number;
}

interface Network {
  coin: string;
  slug: string;
  network: string;
  name: string;
  depositEnable: boolean;
  withdrawEnable: boolean;
  isMain: boolean;
}

interface NetworkList {
  [network: string]: Network;
}

export interface CoinsList {
  [symbol: string]: {
    symbol: string;
    name: string;
    status: boolean;
    slug: string;
    displayPrecision: string;
    networkList: NetworkList;
  };
}

export interface PaymentlinkContextModel {
  price?: string;
  setPrice: (price: string) => void;
  priceCurrency?: string;
  setPriceCurrency: (priceCurrency: string) => void;
  paymentlinkSession?: paymentlinkSessionModel;
  setPaymentlinkSession: (paymentlinkSession: paymentlinkSessionModel) => void;
  coinsListMap: CoinsList;
  setCoinsListMap: (_coinsList: CoinsList) => void;
}

export const PaymentlinkContext = createContext<PaymentlinkContextModel>({
  price: undefined,
  setPrice: () => {},
  priceCurrency: undefined,
  setPriceCurrency: () => {},
  paymentlinkSession: undefined,
  setPaymentlinkSession: () => {},
  coinsListMap: {},
  setCoinsListMap: (_coinsListMap: CoinsList) => {},
});

interface PaymentlinkProviderProps {
  children: ReactNode;
}

const PaymentlinkProvider: React.FC<PaymentlinkProviderProps> = ({
  children,
}) => {
  const [price, setPrice] = useState<string>();
  const [priceCurrency, setPriceCurrency] = useState<string>();
  const [paymentlinkSession, setPaymentlinkSession] = useState<
    paymentlinkSessionModel | undefined
  >(undefined);
  const [coinsListMap, setCoinsListMap] = useState<CoinsList>({});

  const { fetchPayment } = useRequest();
  const { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await fetchPayment(
          id as string,
          "paylink",
          paymentlinkSession?.id,
          "",
          { signal: controller.signal }
        );
        if (response.status === 200) {
          if (
            JSON.stringify(response.session) !==
            JSON.stringify(paymentlinkSession)
          ) {
            setPaymentlinkSession(response.session);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    const interval = setInterval(fetchData, 3000);

    if (
      !(
        paymentlinkSession?.id &&
        paymentlinkSession.wallet &&
        Number(paymentlinkSession.step) < 4
      )
    ) {
      clearInterval(interval); // Clean up the interval
    }

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval
    };
    // eslint-disable-next-line
  }, [paymentlinkSession]);

  return (
    <PaymentlinkContext.Provider
      value={{
        price,
        setPrice,
        priceCurrency,
        setPriceCurrency,
        paymentlinkSession,
        setPaymentlinkSession,
        coinsListMap,
        setCoinsListMap,
      }}
    >
      {children}
    </PaymentlinkContext.Provider>
  );
};

export default PaymentlinkProvider;
