export const DateTime = (unixTime: number): string => {
  const date = new Date(unixTime * 1000); // Convert from seconds to milliseconds

  const options: Intl.DateTimeFormatOptions = {
    hour12: false, // Use 24-hour format
  };

  const localDateTime = date.toLocaleString(undefined, options);

  return localDateTime;
};

export function formatAmount(amount: number, step: string) {
  const a =
    Math.floor(amount / parseFloat(step) + 0.000000000001) * parseFloat(step);
  return parseFloat(a.toFixed(8)).toLocaleString("en-US", {
    maximumFractionDigits: 20,
  });
}

export function convertUTCToLocalTime(utcTimestamp: string) {
  const date = new Date(utcTimestamp);
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const localTime = new Date(date.getTime() - userTimezoneOffset * 60000);
  return localTime.toLocaleString(); // Adjust options as per requirements
}
