import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { AbsoluteCircle } from "./AbsoluteCircle";

export const HomeFeatures = () => {
  return (
    <div className="px-5">
      <div className="p-10 d-flex flex-column justify-content-center align-items-center blue-gray-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
        <AbsoluteCircle position="top-left" />
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5 my-15">
            <h1 className="fs-4x text-white mb-10">
              OxaPay your crypto <br />
              payment gateway
            </h1>
            <p className="text-white fs-3 mb-10 mw-550px">
              Welcome to OxaPay, your premier crypto payment gateway for
              businesses of all sizes. We're more than just a provider; we're
              your business partner, committed to mutual success. We ensure that
              we provide you with the best crypto payment solutions, aiming for
              you to have the best payment experiences and achieve success in
              your business. Our goals are clear: to make "crypto for all" a
              reality, to support businesses in achieving success, and to
              provide the best crypto payment service. Join us at OxaPay, where
              your success is our main goal.
            </p>
          </div>
          <div className="col-12 col-md-5">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 p-0 mt-10">
                <div className="card-light-30 rounded-25px p-5 mb-5 m-4">
                  <img
                    src={toAbsoluteUrl("/media/landing/main/pay.svg")}
                    className="mh-75px"
                    alt="feature"
                  />
                  <div className="pt-6 pb-5">
                    <h1 className="fs-2 mb-5">Flexible Payment Solutions</h1>
                    <p className="fs-5 text-gray-900 m-0">
                      Streamline your cryptocurrency dealings with our adaptable
                      payment options. Designed for convenience, they cater to
                      every requirement, making your financial management
                      effortless.
                    </p>
                  </div>
                </div>
                <div className="card-light-30 rounded-25px p-5 mb-5 m-4">
                  <img
                    src={toAbsoluteUrl("/media/landing/main/autoconvert.svg")}
                    className="mh-75px"
                    alt="feature"
                  />
                  <div className="pt-6 pb-5">
                    <h1 className="fs-2 mb-5">Auto Convert To USDT</h1>
                    <p className="fs-5 text-gray-900 m-0">
                      Navigate market volatility with ease. Our Auto Convert
                      ensures your funds remain stable by instantly converting
                      payments to USDT, offering peace of mind.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 p-0 mb-10">
                <div className="card-light-30 rounded-25px p-5 mb-5 m-4">
                  <img
                    src={toAbsoluteUrl(
                      "/media/landing/main/Telegram-wallet.svg"
                    )}
                    className="mh-75px"
                    alt="feature"
                  />
                  <div className="pt-6 pb-5">
                    <h1 className="fs-2 mb-5">Telegram Wallet</h1>
                    <p className="fs-5 text-gray-900 m-0">
                      Secure and manage your crypto effortlessly with OxaPay's
                      Telegram Wallet. This user-friendly Wallet ensures your
                      digital assets are safe and easily accessible.
                    </p>
                  </div>
                </div>
                <div className="card-light-30 rounded-25px p-5 mb-5 m-4">
                  <img
                    src={toAbsoluteUrl("/media/landing/main/support.svg")}
                    className="mh-75px"
                    alt="feature"
                  />
                  <div className="pt-6 pb-5">
                    <h1 className="fs-2 mb-5">Support & Account Manager</h1>
                    <p className="fs-5 text-gray-900 m-0">
                      Experience unparalleled support with 24/7 access to our
                      dedicated team and your own personal account manager.
                      We’re here to provide tailored solutions and ensure your
                      complete satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
