import React from "react";
import { PaymentlinkContent } from "../../components/PaymentlinkContent";
import PaymentlinkProvider from "../Core/PaymentLinkContext";

export const Paymentlink = () => {
  return (
    <PaymentlinkProvider>
      <PaymentlinkContent />
    </PaymentlinkProvider>
  );
};
