import axios from "axios";

export function useRequest() {
  const API_URL = process.env.REACT_APP_API_URL;

  const FETCH_PAYMENT = `${API_URL}/payment/fetch`;
  const STEPONE_PAYMENT = `${API_URL}/payment/stepOne`;
  const AMOUNT_PAYMENT = `${API_URL}/payment/fetch/amount`;
  const SANDBOX_PAYMENT = `${API_URL}/payment/sandbox`;

  const sandboxRequest = async (id: string, status: number) => {
    const response = await axios.post(SANDBOX_PAYMENT, {
      id,
      status,
    });
    return response.data;
  };

  const fetchAmount = async (
    id: string,
    type: string,
    coin: string,
    option?: any
  ) => {
    const response = await axios.post(
      AMOUNT_PAYMENT,
      {
        id,
        type,
        coin,
      },
      option
    );
    return response.data;
  };

  const fetchPayment = async (
    id: string,
    type: string,
    session?: string,
    referrer?: string,
    option?: any,
    authData?: any,
    userId?: string
  ) => {
    const response = await axios.post(
      FETCH_PAYMENT,
      {
        id,
        type,
        session,
        referrer,
        authData,
        userId,
      },
      option
    );
    return response.data;
  };

  const stepOnePayment = async (
    id: string,
    type: string,
    session: string,
    coin: string,
    network: string,
    name: string,
    email: string,
    authData?: any
  ) => {
    const response = await axios.post(STEPONE_PAYMENT, {
      id,
      type,
      session,
      coin,
      network,
      name,
      email,
      authData,
    });
    return response.data;
  };

  return {
    fetchAmount,
    fetchPayment,
    stepOnePayment,
    sandboxRequest,
  };
}

export {};
