import React from "react";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { BusinessesTypes } from "../../../components/main/BusinessesTypes";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { MerchantInvoiceCode } from "../../../components/main/MerchantInvoiceCode";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { oxaPayCryptoInvoiceFAQs } from "../../services/faqs";

export const MerchantCryptoInvoice = () => {
  return (
    <>
      <div className="px-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center blue-pink-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <AbsoluteCircle position="top-left" />
          <AbsoluteCircle position="bottom-right" />
          <div className="z-index-1">
            <p className="fs-1 text-center text-secondary m-0 mb-3 d-lg-block">
              Crypto payment for merchants with
            </p>
            <h1 className="m-0 text-center text-white large-title fw-bold mb-5">
              OxaPay Merchant
              <br />
              Crypto Invoice
            </h1>
            <p className="fs-3 text-center text-secondary m-0 mb-9">
              Accept crypto payments globally, without KYC/KYB, instantly, and
              <br />
              securely. Streamline your business with OxaPay’s Crypto Invoice
              solution.
            </p>
            <p className="py-3 px-7 rounded bg-blur fs-md-1 fs-5 text-center text-secondary m-0">
              Simple Integration, Enhanced Security, Transparent and Low
              Transaction Fees
            </p>
          </div>
        </div>
      </div>
      <div className="p-5 container-fluid max-width-1550">
        <div
          className="py-20 my-20 m-auto my-10"
          style={{ maxWidth: "1200px" }}
        >
          <h1 className="fs-3x text-dark fw-bold mb-7">
            What is a Crypto Invoice?
          </h1>
          <p className="fs-3 text-gray-800">
            A crypto invoice simplifies the integration of cryptocurrency
            transactions into everyday business, offering a digital solution for
            exchanging goods and services. Unlike traditional payment methods,
            it leverages the efficiency and security of blockchain technology.
            This innovation bridges the gap between cutting-edge digital
            payments and conventional financial processes, allowing for seamless
            transactions worldwide.
            <br />
            <br />
            OxaPay Crypto Invoice is a streamlined service that enables
            businesses to effortlessly accept cryptocurrency payments. By
            integrating this platform, merchants can quickly generate digital
            invoices for goods and services, offering a secure, efficient
            payment option to customers worldwide. This solution simplifies
            transactions, embraces blockchain technology, and provides stability
            against market volatility, enhancing global commerce without the
            need for traditional banking systems.
          </p>
        </div>
      </div>
      <div className="px-5">
        <div className="dark-gray-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <AbsoluteCircle position="top-left" />
          <div
            className="py-20 px-5 my-20 m-auto mt-10"
            style={{ maxWidth: "1200px" }}
          >
            <h1 className="fs-4x text-white mb-2">OxaPay Crypto Invoice</h1>
            <p className="text-gray-700 mb-7 fs-1 badge badge-secondary mb-10">
              Ideal for Diverse Businesses
            </p>

            <p className="fs-3 text-white">
              OxaPay's Crypto Invoice service is revolutionizing the way
              businesses across the globe handle transactions. Trusted by a
              diverse range of industries, from e-commerce and technology
              services to gaming and professional services, this platform is the
              go-to choice for seamless integration of cryptocurrency payments.
              Its widespread adoption underscores its reliability, flexibility,
              and efficiency, making digital transactions easier and more
              accessible for businesses of all sizes. Step into the future of
              payments with OxaPay, where innovation meets practicality.
            </p>
          </div>
          <div className="py-0 overflow-hidden">
            <div className="slider slider-content">
              <div className="slide-track-h align-items-center flex-column w-auto m-auto my-5">
                <div className="d-flex gap-3 flex-row">
                  <BusinessesTypes />
                </div>
              </div>
              <div className="slide-track-reverse-h align-items-center flex-column w-auto m-auto my-5">
                <div className="d-flex gap-3 flex-row">
                  <BusinessesTypes />
                </div>
              </div>
              <div className="slide-track-h align-items-center flex-column w-auto m-auto my-5">
                <div className="d-flex gap-3 flex-row">
                  <BusinessesTypes />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5 container-fluid max-width-1550">
        <div className="py-20 mt-20 mb-5 m-auto" style={{ maxWidth: "1200px" }}>
          <h1 className="fs-3x text-dark fw-bold">Why Choose OxaPay?</h1>
          <p className="text-gray-700 mb-7 fs-1 badge badge-secondary mb-20">
            For Businesses
          </p>
          <div className="row">
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/auto-convert-gray.svg"
                )}
                className="mh-75px mb-5"
                alt="aut-convert"
              />
              <h1 className="fs-1x text-dark fw-bold">
                1. Ability to Auto-Convert to USDT
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Automatically convert cryptocurrencies to USDT to stabilize your
                revenue against crypto market volatility.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/auto-withdraw.svg"
                )}
                className="mh-75px mb-5"
                alt="aut-withdraw"
              />
              <h1 className="fs-1x text-dark fw-bold">
                2. Instant Settlements and Automated Withdrawals
              </h1>
              <p className="fs-3 text-dark mw-550px">
                OxaPay enhances cash flow with instant settlements and automated
                withdrawals, streamlining operations for swift, hassle-free
                financial management.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/bitcoin.svg"
                )}
                className="mh-75px mb-5"
                alt="bitcoin-logo"
              />
              <h1 className="fs-1x text-dark fw-bold">
                3. Multi-Currency Support
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Accept a variety of cryptocurrencies, catering to a global
                customer base with diverse payment preferences.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl("/media/landing/merchant-invoice/stats.svg")}
                className="mh-75px mb-5"
                alt="statistics"
              />
              <h1 className="fs-1x text-dark fw-bold">
                4. Analytics and Reporting Tools
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Gain insights into your transactions with comprehensive
                analytics, helping to inform business decisions.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/support.svg"
                )}
                className="mh-75px mb-5"
                alt="support"
              />
              <h1 className="fs-1x text-dark fw-bold">
                5. 24/7 Customer Support
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Access round-the-clock support for any technical or customer
                service inquiries, ensuring your operations run smoothly.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5 mb-20 container-fluid max-width-1550">
        <div className="pt-10 pb-20 row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <img
              src={toAbsoluteUrl(`/media/landing/merchant-invoice/zerofee.png`)}
              className="w-100 mw-550px d-lg-block m-auto"
              alt="coin"
            />
          </div>
          <div className="col-12 col-md-7 px-10">
            <div className="mw-600px m-auto my-10">
              <h1 className="fs-3x text-dark fw-bold">Why Choose OxaPay?</h1>
              <p className="text-gray-700 mb-7 fs-1 badge badge-secondary mb-20">
                For Customers
              </p>
              <p className="fs-3 text-gray-800">
                OxaPay Crypto Invoice significantly improves customer
                satisfaction by providing a smooth and quick payment process
                that boosts loyalty and contributes to business growth. It
                offers diverse payment options, instant transaction processing,
                and no hidden fees, all within a user-friendly interface. OxaPay
                places a high priority on customer privacy, ensuring sensitive
                information is protected. Additionally, it provides a unique
                feature for blockchain fee-free crypto payments. This allows
                customers to make payments without incurring usual blockchain
                network fees by using the OxaPay Telegram Wallet. By adopting
                OxaPay, businesses can offer a better payment experience,
                thereby fostering greater customer satisfaction and establishing
                lasting relationships.
              </p>
            </div>
          </div>
        </div>
      </div>
      <GradientBackground
        w100
        color={"4b6a9d"}
        className={"px-5 pb-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">
              How to integrate OxaPay
              <br /> invoice in your business?
            </h1>
            <p className="fs-3 text-white mb-15">
              To start using OxaPay Merchant Invoice for your business, first
              sign up for an OxaPay account and get a merchant API key. Then,
              based on the programming language you're using for your project,
              send a request to{" "}
              <span
                className="bg-light px-3 rounded text-dark fs-4 fm-jetbrain"
                style={{ overflowWrap: "break-word" }}
              >
                https://api.oxapay.com/merchants/request
              </span>{" "}
              with the information you need. You can find sample code to help
              you and check out more details in the OxaPay documentation.
            </p>
            <a
              href="https://docs.oxapay.com/api-reference/creating-an-invoice"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <AppButton className="btn-black px-14 py-5">
                Documentation
              </AppButton>
            </a>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <div className="d-flex flex-column overflow-hidden mt-20 mb-5 w-100">
              <MerchantInvoiceCode light />
            </div>
          </div>
        </div>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#fff" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                Stay Updated with Payments
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                Get instant updates on your payments with our Webhooks service.
                Easily set up notifications to be alerted instantly about
                transaction updates directly to your system Just tell us where
                to send the info, and we'll keep you in the loop on every
                payment's progress. Whether a payment goes through, hits a snag,
                or changes status, you'll know right away. It's easy to set up
                and keeps your payment process smooth and worry-free. Ideal for
                any business looking for a hassle-free way to track payments.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl(
                  "media/landing/merchant-invoice/webhook.svg"
                )}
                className="w-100 mw-400px"
                alt="webapp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 pb-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                User-Friendly Invoice Service
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                OxaPay's Crypto Invoice service stands out for its user-friendly
                design, making cryptocurrency transactions simple and accessible
                for businesses of all sizes. With its intuitive interface and
                straightforward integration process, it removes the complexity
                from digital payments, allowing you to focus on what matters
                most – growing your business.
                <br />
                <br />
                OxaPay has innovatively enhanced the Telegram user experience by
                crafting a bespoke and intuitive interface for managing
                invoices. Perfectly tailored for Telegram bots and
                platform-based businesses, including stores operating within
                Telegram, this feature ensures a seamless and secure payment
                process. Its design harmonizes with Telegram's visual style,
                streamlining transactions for an improved user experience.
                Integrating payments directly into the Telegram app not only
                heightens efficiency but also elevates customer satisfaction,
                making payment management both simpler and more engaging for
                users.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/telegram/webapp.png")}
                className="w-100 mw-550px"
                alt="webapp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#CBE0D0" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/merchant-invoice/fiat.svg")}
                className="w-100 mw-550px"
                alt="fiat"
              />
            </div>
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                Choose Fiat as Your Invoice Currency
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                OxaPay Merchant API offers a dynamic invoicing solution,
                enabling businesses to create invoices in one of 40 different
                fiat currencies, such as USD, EUR, GBP, and JPY. This
                versatility allows businesses to tailor their billing to match
                the currency they operate in, ensuring a smooth and integrated
                financial management system. Customers, on the other hand,
                benefit from the flexibility to settle these invoices with the
                cryptocurrency of their choosing, making payments effortless and
                efficient. This dual convenience streamlines the entire payment
                process, fostering a more accessible global marketplace for
                businesses and their clients alike.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FAQSection
        about={"Merchant Crypto Invoice"}
        faqs={oxaPayCryptoInvoiceFAQs}
        questionsSize={"fs-3"}
      />
    </>
  );
};
