import React from "react";
import { MerchantStaticSamples } from "../../app/services/SampleCodes";
import { CodeTabsMaker } from "./CodeTabsMaker";

interface props {
  light?: boolean;
}
export const MerchantStaticCode = ({ light = true }: props) => {
  return (
    <CodeTabsMaker
      color={light ? "white" : undefined}
      tabContent={MerchantStaticSamples}
    />
  );
};
