import React from "react";
import { IconArrowIOS } from "../components/staticIcons/IconArrowIOS";

export const WebappCoinsSkeleton = () => {
  return (
    <>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-100px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
      <div
        className="separator separator-ios my-ios-10 ml-ios-42"
        style={{ width: "calc(100% - 42px)" }}
      ></div>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-75px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
      <div
        className="separator separator-ios my-ios-10 ml-ios-42"
        style={{ width: "calc(100% - 42px)" }}
      ></div>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-100px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
      <div
        className="separator separator-ios my-ios-10 ml-ios-42"
        style={{ width: "calc(100% - 42px)" }}
      ></div>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-90px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
      <div
        className="separator separator-ios my-ios-10 ml-ios-42"
        style={{ width: "calc(100% - 42px)" }}
      ></div>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-80px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
      <div
        className="separator separator-ios my-ios-10 ml-ios-42"
        style={{ width: "calc(100% - 42px)" }}
      ></div>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-100px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
      <div
        className="separator separator-ios my-ios-10 ml-ios-42"
        style={{ width: "calc(100% - 42px)" }}
      ></div>
      <div className="d-flex justify-content-between cursor-pointer">
        <div className="d-flex align-items-center">
          <div className="h-30px w-30px bg-secondary rounded mr-ios-15 shine"></div>
          <div className="h-25px w-150px bg-secondary rounded m-auto shine"></div>
        </div>
        <IconArrowIOS />
      </div>
    </>
  );
};
