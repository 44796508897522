import Swal from "sweetalert2";

export const FailedModalFire = (message: string, title?: string) => {
  Swal.fire({
    title: title ? title : "Oops :(",
    text: message,
    icon: "error",
    padding: "10px 10px 50px",
    width: "500",
    iconColor: "#f1416c",
    showCloseButton: true,
    showConfirmButton: false,
    backdrop: true,
    heightAuto: false,
    background: "var(--kt-card-bg)",
    showClass: {
      popup: "animate__animated animate__faster animate__bounceIn",
    },
    hideClass: {
      popup: "animate__animated animate__faster animate__fadeOutUp",
    },
  });
};
