import React, { useContext } from "react";
import { DonateContext } from "../../app/Core/DonateContext";
import { Check } from "../animatedicons/Check";
import { TextCopyWidget } from "../TextCopyWidget";

export const DonationStepFour = () => {
  const { donateSession } = useContext(DonateContext);
  return (
    <>
      <Check className="mt-20 mb-5" />
      <div className="mb-5 p-10">
        <p className="fs-4">
          {donateSession?.name ? (
            <span className="fw-semibold">Hey {donateSession?.name}</span>
          ) : (
            <span className="fw-semibold">Thank You!</span>
          )}
          <br />
          <span className="text-success fw-semibold">
            Thanks for your donation
          </span>
        </p>
        {donateSession && (
          <p className="rounded px-2 d-inline-block py-1 fs-7 fw-semibold text-dark mb-7 mt-0">
            Transaction Track ID:{" "}
            <TextCopyWidget
              text={`${donateSession.id}`}
              tooltipText="Copy Track ID"
              className="fs-5 mb-2 mt-7 fm-jetbrain"
            />
          </p>
        )}
        {donateSession?.backUrl && (
          <a href={donateSession.backUrl} className="btn btn-success w-200px">
            Return to website
          </a>
        )}
      </div>
    </>
  );
};
