import React from "react";

export const LoadingComponent = () => {
  return (
    <div className="splash-screen">
      <div className="loading-container d-flex flex-column">
        <img
          className="logo-loading"
          src="/media/logos/gray.svg?v=1"
          alt="oxapay loading"
        />
      </div>
    </div>
  );
};
