import React from "react";
import { AccordionMaker, FAQ } from "./AccordionMaker";

interface props {
  faqs: FAQ[];
  about: string;
  questionsSize?: string;
}
export const FAQSection = ({ faqs, about, questionsSize = "fs-1" }: props) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.map(({ q, a }) => ({
      "@type": "Question",
      name: q,
      acceptedAnswer: {
        "@type": "Answer",
        text: a,
      },
    })),
  };

  return (
    <div className="p-5 container-fluid max-width-1550">
      <div className="py-20 row justify-content-center align-items-center">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-4 d-flex justify-content-start align-items-center">
            <h1 className="fs-4x text-dark fw-bold px-5">More About {about}</h1>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <div className="w-100 mw-650px my-15">
              <AccordionMaker content={faqs} questionsSize={questionsSize} />
            </div>
          </div>
        </div>
      </div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </div>
  );
};
