import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { alertsMapStepOneDonate } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import SelectWithImage from "../SelectWithImage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { WaitButtonSpan } from "../WaitButtonSpan";
import Cookies from "universal-cookie";
import { DonateContext } from "../../app/Core/DonateContext";

export interface NetworkOption {
  label: string;
  value: string;
  image: string;
  isMain?: boolean;
  disable?: boolean;
}

interface props {
  setActiveIndex: (activeIndex: number) => void;
  showOptionsStatus: (status: boolean) => void;
}

export const DonationStepOne = ({
  showOptionsStatus,
  setActiveIndex,
}: props) => {
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    name: {
      show: false,
      require: false,
    },
    email: {
      show: false,
      require: false,
    },
  });

  const { fetchPayment, stepOnePayment } = useRequest();
  const [networks, setNetworks] = useState<NetworkOption[] | undefined>();

  const [coinIsOpen, setCoinIsOpen] = useState<boolean>(false);
  const [networkIsOpen, setNetworkIsOpen] = useState<boolean>(false);
  const { id, sessionId } = useParams();
  const [session, setSession] = useState<string>();

  const cookies = new Cookies();
  const navigate = useNavigate();
  const { coinsListMap, setCoinsListMap, donateSession, setDonateSession } =
    useContext(DonateContext);

  useEffect(() => {
    coinIsOpen || networkIsOpen
      ? showOptionsStatus(true)
      : showOptionsStatus(false);
    // eslint-disable-next-line
  }, [coinIsOpen, networkIsOpen]);

  useEffect(() => {
    const referrer = document.referrer;
    fetchPayment(id as string, "donate", sessionId ?? "", referrer)
      .then((res) => {
        if (res.status === 200) {
          if (Number(res.session.step) === 4) {
            setActiveIndex(4);
          } else {
            if (!sessionId) {
              const url = new URL(window.location.href);
              const valueToPush = res.session.id;
              if (!url.pathname.endsWith("/")) {
                url.pathname += "/";
              }
              url.pathname += valueToPush;
              window.history.pushState({}, "", url.toString());
            } else {
              const currentUrl = window.location.href;
              const updatedUrl = currentUrl.replace(sessionId, res.session.id);
              window.history.pushState({}, "", updatedUrl);
            }

            setActiveIndex(Number(res.session.step) - 1);
            setFields(res.fields);
            setCoinsListMap(res.coins);
            setDonateSession(res.session);
            setSession(res.session.id);

            formik.setValues({
              name: res.session.name,
              email: res.session.email,
              coin: res.session.coin,
              network: res.session.network,
            });

            const ref = cookies.get("ref");
            !ref &&
              cookies.set("ref", res.refId, {
                domain: ".oxapay.com",
                path: "/",
              });

            setFetching(false);
          }
        } else if (res.status === 0 && res.errorNum) {
          navigate("/error/404");
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  const emailRegex = /^([a-zA-Z0-9.]+@+[a-zA-Z]+(\.)+[a-zA-Z]{2,8})$/;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      coin: "",
      network: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().when([], {
        is: () => fields.name.show && fields.name.require,
        then: Yup.string()
          .min(3, "Name must be at least 3 characters")
          .max(30, "Name must be less than 30 characters")
          .required("Name is required"),
        otherwise: Yup.string()
          .min(3, "Name must be at least 3 characters")
          .max(30, "Name must be less than 30 characters"),
      }),

      email: Yup.string()
        .test("email", "The email is not valid", (value) => {
          if (!value) {
            return true; // No error for empty value
          }
          return emailRegex.test(value);
        })
        .when([], {
          is: () => fields.email.show && fields.email.require,
          then: Yup.string().required("Email is required"),
          otherwise: Yup.string(),
        }),
      coin: Yup.string().required("Currency is require"),
      network: Yup.string().required("Network is require"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (
        values.name === donateSession?.name &&
        values.email === donateSession.email &&
        values.coin === donateSession.coin &&
        values.network === donateSession.network
      ) {
        setActiveIndex(Number(donateSession.step) - 1);
      } else {
        setLoading(true);
        setSubmitting(true);
        try {
          const res = await stepOnePayment(
            id ?? "",
            "donate",
            session ?? "",
            values.coin,
            values.network,
            values.name,
            values.email
          );
          if (res.status === 200) {
            setActiveIndex(res.session.step - 1);
            setDonateSession(res.session);
          } else {
            FailedModalFire(alertsMapStepOneDonate[res.errorNum.toString()]);
          }
        } catch (error) {
          errorHandler(error as Error);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      }
    },
  });

  const optionsCoin =
    coinsListMap &&
    Object.entries(coinsListMap)
      .filter(([_symbol, coin]) => coin.status === true)
      .map(([symbol, coin]) => ({
        value: symbol,
        label: `${coin.name} (${symbol})`,
        image: `/media/svg/coins/${coin?.slug}.svg`,
      }));

  useEffect(() => {
    if (formik.values.coin && coinsListMap) {
      const Coin = coinsListMap[formik.values.coin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const optionsNetwork = Object.entries(networks).map(
          ([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.depositEnable,
            isMain: network?.isMain,
          })
        );
        setNetworks(optionsNetwork);
      }
    }
    formik.setFieldValue("network", "");
    // eslint-disable-next-line
  }, [formik.values.coin]);

  useEffect(() => {
    formik.setFieldValue("network", formik.values.network);
    // eslint-disable-next-line
  }, [formik.values.network]);

  useEffect(() => {
    networks?.length === 1 &&
      networks[0].isMain &&
      !networks[0].disable &&
      formik.setFieldValue("network", networks[0].value);
    // eslint-disable-next-line
  }, [networks]);

  return fetching ? (
    <div className="px-10 pb-0 pt-10 overflow-item">
      <div className="mb-5">
        <div className="h-20px w-25 bg-secondary rounded shine mb-2 text-left"></div>
        <div className="h-45px w-100 bg-secondary rounded m-auto shine"></div>
      </div>
      <div className="mb-10">
        <div className="h-20px w-25 bg-secondary rounded shine mb-2 text-left"></div>
        <div className="h-45px w-100 bg-secondary rounded m-auto shine"></div>
      </div>
      <div className="h-45px w-100 bg-secondary rounded m-auto shine mb-10"></div>
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <div className="px-10 pb-0 pt-10 overflow-item">
        {fields.name.show && (
          <div className="mb-5">
            <label className="form-label text-start">
              Your name
              {fields.name.require && <span className="text-danger">*</span>}
            </label>
            <input
              type="text"
              className={`form-control form-control-solid ${
                formik.errors.name &&
                formik.touched.name &&
                "is-invalid border border-danger"
              }`}
              autoComplete="off"
              name="name"
              placeholder="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.errors.name && formik.touched.name && (
              <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                {formik.errors.name}
              </p>
            )}
          </div>
        )}
        {fields.email.show && (
          <div className="mb-5">
            <label className="form-label text-start">
              Your email address
              {fields.email.require && <span className="text-danger">*</span>}
            </label>
            <input
              type="text"
              className={`form-control form-control-solid ${
                formik.errors.email && formik.touched.email && "border-danger"
              }`}
              autoComplete="off"
              name="email"
              placeholder="email address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.errors.email && formik.touched.email && (
              <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                {formik.errors.email}
              </p>
            )}
          </div>
        )}
        {optionsCoin && (
          <>
            <label className="form-label text-start">Choose currency</label>
            <div className="position-relative mb-5">
              <SelectWithImage
                height={200}
                showOptionsStatus={(status) => setCoinIsOpen(status)}
                className={`${
                  formik.errors.coin &&
                  formik.touched.coin &&
                  "border border-danger"
                }`}
                defaultValue={"Choose currency"}
                options={optionsCoin}
                value={formik.values.coin}
                onChange={(value) => {
                  formik.setFieldValue("coin", value);
                }}
              />

              {formik.errors.coin && formik.touched.coin && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.coin}
                </p>
              )}
            </div>
          </>
        )}
        {networks &&
          !(
            networks.length === 1 &&
            networks[0].isMain &&
            !networks[0].disable
          ) && (
            <>
              <label className="form-label text-start">Choose network</label>
              <div className="position-relative mb-10">
                <SelectWithImage
                  showOptionsStatus={(status) => setNetworkIsOpen(status)}
                  className={`${
                    formik.errors.network &&
                    formik.touched.network &&
                    "border border-danger"
                  }`}
                  options={networks}
                  defaultValue={"Choose network"}
                  value={formik.values.network}
                  onChange={(network) =>
                    formik.setFieldValue("network", network)
                  }
                />
                {formik.errors.network && formik.touched.network && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.network}
                  </p>
                )}
              </div>
            </>
          )}
      </div>
      {donateSession && (
        <div className="px-10 mb-10">
          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={formik.isSubmitting}
          >
            {loading ? <WaitButtonSpan /> : "Proceed to payment"}
          </button>
        </div>
      )}
    </form>
  );
};
