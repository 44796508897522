import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

export const TelegramChatWidget = () => {
  return (
    <div className="telegram-widget-support me-3 p-3">
      <a
        href="https://t.me/danielsup"
        target="_blank"
        rel="noreferrer"
        className="app-engage-btn text-white py-1 m-0"
      >
        <span className="svg-icon svg-icon-1 pt-1">
          <img
            src={toAbsoluteUrl(`/media/custom/telegram-widget-support.svg`)}
            className="w-75px mb-1"
            alt="OxaPay blog"
          />
        </span>
      </a>
    </div>
  );
};
