import axios from "axios";
import { createContext, useState, ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { alertsMapFetchPayment } from "../services/alertsMap";
import { errorHandler } from "../services/errorHandler";
import { FailedModalFire } from "../services/failedSwalFire";
import { useRequest } from "../services/userHttpRequests";

interface MerchantsSessionModel {
  sandbox: boolean;
  amount: string;
  failureUrl: string;
  returnUrl: string;
  coin: string;
  confirm: string;
  confirmRequired: string;
  network: string;
  step: string;
  wallet: string;
  duration: string;
  expireAt: number;
}

interface Network {
  coin: string;
  slug: string;
  network: string;
  name: string;
  depositEnable: boolean;
  withdrawEnable: boolean;
  isMain: boolean;
}

interface NetworkList {
  [network: string]: Network;
}

export interface CoinsList {
  [symbol: string]: {
    symbol: string;
    name: string;
    status: boolean;
    slug: string;
    displayPrecision: string;
    networkList: NetworkList;
  };
}

export interface MerchantsContextModel {
  expireAt?: number;
  setExpireAt: (expireAt: number) => void;
  expired: boolean;
  setExpired: (expired: boolean) => void;
  currency?: string;
  setCurrency: (currency: string) => void;
  selectedCoin?: string;
  setSelectedCoin: (selectedCoin: string) => void;
  selectedNetwork?: string;
  setSelectedNetwork: (selectedNetwork: string) => void;
  logo?: string;
  setLogo: (logo: string) => void;
  price?: string;
  setPrice: (price: string) => void;
  merchantsSession?: MerchantsSessionModel;
  setMerchantsSession: (merchantsSession: MerchantsSessionModel) => void;
  coinsListMap: CoinsList;
  setCoinsListMap: (_coinsList: CoinsList) => void;
}

export const MerchantsContext = createContext<MerchantsContextModel>({
  expireAt: undefined,
  setExpireAt: () => {},
  expired: false,
  setExpired: () => {},
  currency: undefined,
  setCurrency: () => {},
  selectedCoin: undefined,
  setSelectedCoin: () => {},
  selectedNetwork: undefined,
  setSelectedNetwork: () => {},
  logo: undefined,
  setLogo: () => {},
  price: undefined,
  setPrice: () => {},
  merchantsSession: undefined,
  setMerchantsSession: () => {},
  coinsListMap: {},
  setCoinsListMap: (_coinsListMap: CoinsList) => {},
});

interface MerchantsProviderProps {
  children: ReactNode;
}

const MerchantsProvider: React.FC<MerchantsProviderProps> = ({ children }) => {
  const [currency, setCurrency] = useState<string>();
  const [logo, setLogo] = useState<string>();
  const [price, setPrice] = useState<string>();
  const [merchantsSession, setMerchantsSession] = useState<
    MerchantsSessionModel | undefined
  >(undefined);
  const [coinsListMap, setCoinsListMap] = useState<CoinsList>({});
  const [selectedCoin, setSelectedCoin] = useState<string>();
  const [selectedNetwork, setSelectedNetwork] = useState<string>();
  const [expired, setExpired] = useState<boolean>(false);
  const [expireAt, setExpireAt] = useState<number>();

  const { fetchPayment } = useRequest();
  const { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const res = await fetchPayment(id as string, "merchant", "", "", {
          signal: controller.signal,
        });
        if (res.status === 200) {
          if (
            JSON.stringify(res.session) !== JSON.stringify(merchantsSession)
          ) {
            setMerchantsSession(res.session);
          }
        } else if (res.status === 0 && res.errorNum) {
          if (res.errorNum === -103) {
            setExpired(true);
          } else {
            FailedModalFire(alertsMapFetchPayment[res.errorNum.toString()]);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    const interval = setInterval(fetchData, 3000);

    if (
      !(
        merchantsSession &&
        merchantsSession.wallet &&
        Number(merchantsSession.step) < 4
      )
    ) {
      clearInterval(interval); // Clean up the interval
    }

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval
    };
    // eslint-disable-next-line
  }, [merchantsSession]);

  return (
    <MerchantsContext.Provider
      value={{
        expireAt,
        setExpireAt,
        expired,
        setExpired,
        currency,
        setCurrency,
        selectedCoin,
        setSelectedCoin,
        selectedNetwork,
        setSelectedNetwork,
        logo,
        setLogo,
        price,
        setPrice,
        merchantsSession,
        setMerchantsSession,
        coinsListMap,
        setCoinsListMap,
      }}
    >
      {children}
    </MerchantsContext.Provider>
  );
};

export default MerchantsProvider;
