import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

export const AutoConvert = () => {
  return (
    <div className="px-5 pb-5">
      <div
        className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
        style={{ background: "#e7efff" }}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <img
              src={toAbsoluteUrl("/media/landing/swap/auto-convert.png")}
              className="w-100 mw-600px my-10"
              alt="coin"
            />
          </div>
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-dark fw-bold mb-7">
              What is Auto Convert?
            </h1>
            <p className="fs-3 text-gray-800 mb-15">
              Introducing an exciting addition to the Generate Merchant API key
              form: <b>Auto Convert</b>.<br /> This innovative feature offers
              seamless currency conversion, ensuring that payments made towards
              invoices created with this API key are instantly transformed into
              USDT and seamlessly integrated into your balance. Gone are the
              days of fretting over crypto market fluctuations. With Auto
              Convert, you can navigate the digital landscape with confidence,
              knowing that your finances are shielded from volatility. Embrace
              this powerful tool and unlock a new level of stability in your
              transactions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
