import React, { useContext, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { MerchantsContext } from "../app/Core/MerchantContext";
import { ExpiredInvoice } from "../app/pages/ExpiredInvoice";
import { PaidInvoice } from "../app/pages/PaidInvoice";
import { MerchantStepFour } from "./merchant/MerchantStepFour";
import { MerchantStepOne } from "./merchant/MerchantStepOne";
import { MerchantStepThree } from "./merchant/MerchantStepThree";
import { MerchantStepTwo } from "./merchant/MerchantStepTwo";
import { PayPagesFooter } from "./PayPagesFooter";
import { IconBackArrow } from "./staticIcons/IconBackArrow";
import { TimeCounter } from "./TimeCounter";

export const MerchantRegular = () => {
  const [dropdownIsOpen, setDropDownIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { logo, expired, expireAt } = useContext(MerchantsContext);
  useEffect(() => {
    expired && setActiveIndex(4);
  }, [expired]);

  useEffect(() => {
    activeIndex < 2 &&
      expireAt &&
      expireAt < Math.floor(Date.now() / 1000) &&
      setActiveIndex(4);
  }, [expireAt, activeIndex]);

  return (
    <div className="d-flex flex-column-fluid flex-lg-row justify-content-center">
      <div className="d-flex flex-center flex-column w-lg-50 w-100 p-lg-10 p-md-10 px-10">
        <div className="w-md-500px w-100 d-flex px-7 justify-content-between mb-5">
          <div>
            {logo && <img alt="merchant logo" src={logo} height="25px" />}
          </div>

          {!expired && expireAt && activeIndex < 2 && (
            <p className="ms-2 fs-5 m-0">
              Invoice expire at{" "}
              <span className="text-primary fw-bold">
                <TimeCounter endtime={expireAt} />
              </span>
            </p>
          )}
        </div>
        <div className="card shadow w-md-500px w-100">
          <Carousel
            className={dropdownIsOpen ? "stepper-carousel" : ""}
            activeIndex={activeIndex}
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <MerchantStepOne
                setActiveIndex={(res) => setActiveIndex(res)}
                showOptionsStatus={(DropDownStatus) =>
                  setDropDownIsOpen(DropDownStatus)
                }
              />
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-header align-items-center px-10">
                <button
                  className="btn ps-0 svg-icon svg-icon-muted svg-icon-2hx backicon"
                  onClick={() => setActiveIndex(activeIndex - 1)}
                >
                  <IconBackArrow />
                </button>
                <h3 className="card-title">Pay details</h3>
              </div>
              <MerchantStepTwo setActiveIndex={(res) => setActiveIndex(res)} />
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <MerchantStepThree
                activeStep={activeIndex}
                setActiveIndex={(res) => setActiveIndex(res)}
              />
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <MerchantStepFour />
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <ExpiredInvoice />
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <PaidInvoice />
            </Carousel.Item>
          </Carousel>
          <div className="d-flex align-items-center justify-content-between py-4 px-10 bg-light rounded-bottom">
            <div className="carousel carousel-custom">
              {activeIndex < 4 && (
                <ol className="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary">
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 0 && "active"
                    }`}
                  ></li>
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 1 && "active"
                    }`}
                  ></li>
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 2 && "active"
                    }`}
                  ></li>
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 3 && "active"
                    }`}
                  ></li>
                </ol>
              )}
            </div>

            <PayPagesFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
