import React, { ReactNode } from "react";

interface Props {
  w100?: boolean;
  secondaryColor?: string;
  color: string;
  children: ReactNode;
  decoratives?: ReactNode;
  className?: string;
}

export const GradientBackground = ({
  w100 = false,
  children,
  secondaryColor,
  color,
  decoratives,
  className = "px-5 pb-5",
}: Props) => {
  return (
    <div className={className}>
      <div
        className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
        style={{
          background: "#000",
          backgroundImage: `linear-gradient(45deg, #${
            secondaryColor ?? "151515"
          } 21%, #${color ?? "000"} 86%)`,
        }}
      >
        {decoratives}
        <div className={`z-index-1 ${w100 && "w-100"}`}>{children}</div>
      </div>
    </div>
  );
};
