import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { DoneDonate } from "../app/pages/DoneDonate";
import { DonationStepFour } from "./donation/DonationStepFour";
import { DonationStepOne } from "./donation/DonationStepOne";
import { DonationStepThree } from "./donation/DonationStepThree";
import { DonationStepTwo } from "./donation/DonationStepTwo";
import { PayPagesFooter } from "./PayPagesFooter";
import { IconBackArrow } from "./staticIcons/IconBackArrow";

export const DonationContent = () => {
  const [dropdownIsOpen, setDropDownIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <div className="d-flex flex-column-fluid flex-lg-row justify-content-center">
      <div className="d-flex flex-center w-lg-50 w-100 p-lg-10 p-md-10 px-10">
        <div className="card shadow w-md-500px w-100">
          <Carousel
            className={dropdownIsOpen ? "stepper-carousel" : ""}
            activeIndex={activeIndex}
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <div className="card-header align-items-center px-10 justify-content-start">
                <h3 className="card-title test3000">Donation Page</h3>
              </div>
              <DonationStepOne
                setActiveIndex={(res) => setActiveIndex(res)}
                showOptionsStatus={(DropDownStatus) =>
                  setDropDownIsOpen(DropDownStatus)
                }
              />
            </Carousel.Item>
            <Carousel.Item>
              <>
                <div className="card-header align-items-center px-10">
                  <button
                    className="btn ps-0 svg-icon svg-icon-muted svg-icon-2hx backicon"
                    onClick={() => setActiveIndex(activeIndex - 1)}
                  >
                    <IconBackArrow />
                  </button>
                  <h3 className="card-title">Pay details</h3>
                </div>

                <DonationStepTwo
                  setActiveIndex={(res) => setActiveIndex(res)}
                />
              </>
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <DonationStepThree
                activeStep={activeIndex}
                setActiveIndex={(res) => setActiveIndex(res)}
              />
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <DonationStepFour />
            </Carousel.Item>
            <Carousel.Item className="text-center">
              <DoneDonate />
            </Carousel.Item>
          </Carousel>
          <div className="d-flex align-items-center justify-content-between py-4 px-10 bg-light rounded-bottom">
            <div className="carousel carousel-custom">
              {activeIndex < 4 && (
                <ol className="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary">
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 0 && "active"
                    }`}
                  ></li>
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 1 && "active"
                    }`}
                  ></li>
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 2 && "active"
                    }`}
                  ></li>
                  <li
                    className={`carouselcheck ms-1 ${
                      activeIndex === 3 && "active"
                    }`}
                  ></li>
                </ol>
              )}
            </div>

            <PayPagesFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
