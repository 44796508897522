import React from "react";
import { Link, useParams } from "react-router-dom";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import TypingEffect from "../../../components/main/TypingEffect";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { coinPrivateQuestions, coinPublicQuestions } from "../../services/faqs";
import { coinsPageData, pluginsPageData } from "../../services/landingDatas";

export const Coin = () => {
  const { coinName } = useParams();

  const faqs = [
    ...coinPublicQuestions(coinName ? coinsPageData[coinName].name : ""),
  ];
  faqs.unshift(...coinPrivateQuestions(coinName ? coinName : ""));

  return (
    <>
      <GradientBackground
        color={coinName ? coinsPageData[coinName].color : "000000"}
        decoratives={
          <>
            <img
              className="d-none d-lg-block position-absolute pointer-event-none z-index-0 w-100 mw-500px"
              src={toAbsoluteUrl(`/media/landing/coins/${coinName}.png`)}
              style={{
                top: -270,
                right: 0,
                rotate: "-110deg",
                filter: "blur(10px)",
              }}
              alt={"design item"}
            />
            <img
              className="d-none d-lg-block position-absolute pointer-event-none z-index-0 w-100 mw-500px"
              src={toAbsoluteUrl(`/media/landing/coins/${coinName}.png`)}
              style={{
                bottom: -270,
                right: 250,
                rotate: "-65deg",
                filter: "blur(10px)",
              }}
              alt={"design item"}
            />
            <AbsoluteCircle position="top-left" />
          </>
        }
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <img
              src={toAbsoluteUrl(`/media/landing/coins/${coinName}.png`)}
              className="w-100 mw-600px"
              alt="coin"
            />
          </div>
          <div className="col-12 col-md-7 d-flex flex-column justify-content-center align-items-center">
            <p className="mx-0 mt-10 mb-20 text-center text-white large-title fw-bold mb-5">
              Accept {coinName ? coinsPageData[coinName].name : "Unknown"}{" "}
              <br />
              In Your{" "}
              <TypingEffect textArray={["Business", "Website", "Apps"]} />
            </p>
            <p className="fs-3 text-white mb-15 mw-700px">
              Discover the simplicity of crypto transactions with OxaPay, your
              go-to payment gateway for effortless acceptance of{" "}
              {coinName ? coinsPageData[coinName].name : "Unknown"} payments and
              donations. Experience the ease and convenience firsthand - try our{" "}
              {coinName ? coinsPageData[coinName].name : "Unknown"} payment
              gateway NOW!
            </p>
          </div>
        </div>
      </GradientBackground>
      <div className="p-5 container-fluid max-width-1550">
        <div className="py-20 row justify-content-center align-items-center">
          <div className="col-12 col-md-7 px-10">
            <div className="mw-600px m-auto my-10">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                What is {coinName ? coinsPageData[coinName].name : "Unknown"}{" "}
                Payment?
              </h1>
              <p
                className="fs-3 text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: coinName ? coinsPageData[coinName].whatis : "",
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-5">
            <img
              src={toAbsoluteUrl(`/media/landing/coins/${coinName}.png`)}
              className="w-100 mw-500px d-none d-lg-block "
              alt="coin"
            />
          </div>
        </div>
      </div>
      <div className="p-5">
        <div
          className="justify-content-center align-items-center w-100 rounded-35px overflow-hidden"
          style={{
            background: `#${
              coinName ? coinsPageData[coinName].secondaryColor : "000000"
            }`,
          }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <img
                src={toAbsoluteUrl(`/media/landing/coins/shop-${coinName}.png`)}
                className="w-100"
                alt="coin"
              />
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-start align-items-center">
              <div className="d-flex justfiy-content-center flex-column align-items-start px-10 py-12">
                <h1
                  className="fs-3x fw-bold mb-7"
                  style={{
                    color: `#${
                      coinName ? coinsPageData[coinName].titleColor : "000000"
                    }`,
                  }}
                >
                  How to accept{" "}
                  {coinName ? coinsPageData[coinName].name : "Unknown"}{" "}
                  payments?
                </h1>
                <p className="fs-3 text-gray-800 fw-semibold mb-3">
                  Accepting{" "}
                  {coinName ? coinsPageData[coinName].name : "Unknown"} payments
                  with OxaPay is easy thanks to our all-in-one service:
                </p>
                <p className="fs-3 text-gray-800 fw-semibold">
                  <b>Merchant Web Services:</b> API, Invoice, Static Address,
                  and White Label solutions.
                </p>
                <p className="fs-3 text-gray-800 fw-semibold">
                  <b>Payment Links:</b> Effortless payment acceptance with no
                  coding required.
                </p>
                <p className="fs-3 text-gray-800 fw-semibold">
                  <b>Donations:</b> Seamlessly receive various donations.
                </p>
                <p className="fs-3 text-gray-800 fw-semibold">
                  <b>Website Plugins:</b> (
                  {Object.keys(pluginsPageData).map((plugin, index) => (
                    <React.Fragment key={plugin}>
                      <Link className="text-dark" to={`/plugins/${plugin}`}>
                        {pluginsPageData[plugin].name}
                      </Link>
                      {index !== Object.keys(pluginsPageData).length - 1 &&
                        ", "}
                    </React.Fragment>
                  ))}
                  ) Choose for easy integration.
                </p>
                <p className="fs-3 text-gray-800 fw-semibold">
                  <b>Payout API:</b> Automate and manage bulk transfers
                  cost-effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQSection
        about={`${
          coinName ? coinsPageData[coinName].name : "Unknown"
        } Payments`}
        faqs={faqs}
        questionsSize={"fs-3"}
      />
    </>
  );
};
