import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

export const ExpiredInvoice = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center py-15 h-400px">
      <div className="d-flex justify-content-center align-items-center mb-ios-22">
        <img
          className="w-50px me-4 theme-light-show"
          src={toAbsoluteUrl(`/media/webapp/expired.svg`)}
          alt={"expired"}
        />
        <img
          className="w-50px me-4 theme-dark-show"
          src={toAbsoluteUrl(`/media/webapp/expired-dark.svg`)}
          alt={"expired"}
        />
        <span className="fs-1 fw-ios-700">Expired link</span>
      </div>
      <p className="text-center fs-4 m-0">The link of invoice was expired</p>
    </div>
  );
};
