import React, { useContext, useEffect, useState } from "react";
import { DonateContext } from "../../app/Core/DonateContext";
import {
  getTokenContractAddress,
  networkschainIdMap,
  networksNameMap,
} from "../../app/services/maps";
import { QrDataWalletSuggest } from "../QrDataWalletSuggest";

import { TextCopyWidget } from "../TextCopyWidget";

interface props {
  setActiveIndex: (activeIndex: number) => void;
}

export const DonationStepTwo = ({ setActiveIndex }: props) => {
  const [qrLoading, setQrLoading] = useState(true);
  const { coinsListMap, donateSession } = useContext(DonateContext);

  useEffect(() => {
    setQrLoading(true);
  }, [donateSession?.wallet]);

  useEffect(() => {
    Number(donateSession?.step) === 3 && setActiveIndex(2);
    // eslint-disable-next-line
  }, [donateSession?.step]);

  return (
    <div className="p-10 text-center">
      {donateSession && (
        <>
          <div>
            <h4 className="fs-5 fw-semibold text-gray-800 mt-4">
              Send{" "}
              <span className="text-primary fw-bold">
                {coinsListMap[donateSession?.coin]?.name}
              </span>{" "}
              {!coinsListMap[donateSession.coin]?.networkList[
                donateSession.network
              ]?.isMain && (
                <span className="badge badge-light-primary text-primary">
                  {donateSession.network}
                </span>
              )}{" "}
              to this address
            </h4>

            <TextCopyWidget
              text={donateSession?.wallet}
              tooltipText="Copy Address"
              className="fs-5 mb-2 mt-7 fm-jetbrain"
              qrCode={true}
            />
          </div>
          <div className="bg-light-primary rounded d-flex flex-column flex-md-row justify-content-center text-start align-items-center mt-10 mb-8 gap-3 p-3">
            <div>
              {qrLoading && (
                <div className="p-2 h-150px w-150px bg-secondary rounded shine"></div>
              )}
              {donateSession?.wallet && (
                <div
                  className={`bg-white p-2 rounded d-block ${
                    qrLoading && "d-none"
                  }`}
                >
                  <img
                    src={`http://api.qrserver.com/v1/create-qr-code/?data=${
                      networksNameMap[donateSession.network]
                    }${donateSession?.wallet}${
                      networkschainIdMap[donateSession.network]
                    }${getTokenContractAddress(
                      donateSession.coin,
                      donateSession.network
                    )}&size=150x150`}
                    alt="QR code"
                    style={{ width: "150px" }}
                    onLoad={() => {
                      setQrLoading(false);
                    }}
                  />
                </div>
              )}
            </div>
            <QrDataWalletSuggest />
          </div>
        </>
      )}
    </div>
  );
};
