import React from "react";
import { Link } from "react-router-dom";
import { pluginsPageData } from "../../app/services/landingDatas";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

interface props {
  name: string;
  download?: boolean;
}
export const PluginCard = ({ name, download = true }: props) => {
  return (
    <>
      {/* <Link to={`/plugins-list/${name}`}> */}
      <a target="_blank" href={pluginsPageData[name].link}>
        <div
          className={`hover-scale bg-gray px-10 py-8 rounded-20px position-relative ${
            download ? "mb-3" : "mb-5 mx-2"
          } `}
        >
          <div className="w-70px h-70px position-absolute bg-white top-right symbol symbol-circle d-flex justify-content-center align-items-center rotate-45">
            <i className="fa-solid fa-arrow-right text-black fs-3x"></i>
          </div>
          <img
            className="h-70px mb-3"
            alt={pluginsPageData[name].name}
            src={toAbsoluteUrl(`/media/landing/plugins/${name}.svg`)}
          />
          <h2 className="fs-2 text-white">{pluginsPageData[name].name}</h2>
          <p className="text-white fs-8">
            {pluginsPageData[name].shortDescription}
          </p>
        </div>
      </a>
      {download && (
        <a
          href={pluginsPageData[name].download}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <button className="btn bg-gray bg-hover-dark text-white px-10 w-100 rounded-20px mb-15">
            <i className="fa-solid fa-download fs-2 text-white me-2"></i>
            <span>Download</span>
          </button>
        </a>
      )}
    </>
  );
};
