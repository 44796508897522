/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";

const PrivacyPolicy: FC = () => {
  return (
    <div className="modal fade" id="privacy" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Privacy policy</h5>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="svg-icon svg-icon-2x">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <p>
              <b>Cookie Usage</b>
              <br />
              We employ various technologies, including cookies, clear GIFs or
              web beacons, and other identifiers or technologies collectively
              referred to as cookies, to gather information about your
              activities on our site. These technologies may be utilized by
              first and third parties. Cookies enable us to collect data that
              helps us improve and customize your experience.
              <br />
              <br />
              <b>Sharing of Personal Information</b>
              <br />
              In certain cases, we may share the information collected through
              cookies with third parties, including our affiliates and other
              entities acting on our behalf. These third parties are subject to
              their privacy policies. The shared information may be used on our
              website, other websites or services operated by us, or by third
              parties. It is important to note that other parties may also
              collect personally identifiable information about your online
              activities across different websites and over time, including when
              you use our websites or online services.
              <br />
              <br />
              <b>Purpose of Collecting Personal Information</b>
              <br />
              The personal information we collect is used for several purposes,
              including:
              <br />
              <br />
              1. Providing our services, including customer support.
              <br />
              2. Processing transactions and notifying you about them.
              <br />
              3. Resolving disputes, collecting fees, and troubleshooting
              issues.
              <br />
              4. Communicating important account-related matters and responding
              to your inquiries, comments, and complaints.
              <br />
              5. Compliance with applicable laws and regulations.
              <br />
              7. Monitoring and reporting compliance issues.
              <br />
              8. Customizing, measuring, and enhancing our business, services,
              websites, and applications.
              <br />
              9. Performing data analysis.
              <br />
              10. Delivering targeted marketing, service update notices, and
              promotional offers based on your preferences.
              <br />
              11. Performing risk management, fraud prevention, and ensuring the
              security of our networks and systems.
              <br />
              12. Enforcing our contractual terms.
              <br />
              <br />
              <b>Data Security and Storage</b>
              <br />
              We prioritize the security of personal information and employ
              administrative, technical, and physical measures to protect the
              data we possess. Internal policies and procedures are also in
              place to address data security. We retain data in compliance with
              applicable laws and regulations.
              <br />
              <br />
              <b>Sharing Data with Third Parties</b>
              <br />
              We may share the information we collect with our affiliates and
              third-party service providers who assist us with our business
              operations and service delivery. Examples of such service
              providers include cloud service providers for infrastructure,
              customer service solution providers, and website analytics
              providers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PrivacyPolicy };
