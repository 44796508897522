import React from "react";

export const RotateBlock = () => {
  return (
    <div className="container-x">
      <div className="block">
        <div className="face top"></div>
        <div className="face right"></div>
        <div className="face left"></div>
        <div className="face front"></div>
        <div className="face back"></div>
        <div className="face bottom"></div>
      </div>
    </div>
  );
};
