import React from "react";
import { MerchantInvoiceSamples } from "../../app/services/SampleCodes";
import { CodeTabsMaker } from "./CodeTabsMaker";

interface props {
  light?: boolean;
}
export const MerchantInvoiceCode = ({ light = false }: props) => {
  return (
    <CodeTabsMaker
      tabContent={MerchantInvoiceSamples}
      color={light ? "white" : undefined}
    />
  );
};
