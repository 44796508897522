import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";
import { MerchantRegular } from "../../components/MerchantRegular";
import { MerchantWebapp } from "../../components/MerchantWebapp";
import MerchantsProvider from "../Core/MerchantContext";
import { loadTelegramScript } from "../services/loadTelegramScript";

export const Merchant = () => {
  const [searchParams] = useSearchParams();
  const [webapp, setWebapp] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [sourceDetected, setSourceDetected] = useState(false);
  useEffect(() => {
    loadTelegramScript(() => {
      setScriptLoaded(true);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      (window as any).Telegram?.WebApp?.ready();
      const queryId = (window as any)?.Telegram?.WebApp?.initDataUnsafe
        ?.query_id;

      if (queryId) {
        setWebapp(true);
        setSourceDetected(true);
      } else {
        if (
          searchParams.get("webapp") &&
          searchParams.get("webapp") === "true"
        ) {
          setWebapp(true);
          setSourceDetected(true);
        } else {
          setWebapp(false);
          setSourceDetected(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [scriptLoaded]);

  return (
    <MerchantsProvider>
      {sourceDetected ? (
        webapp ? (
          <MerchantWebapp />
        ) : (
          <MerchantRegular />
        )
      ) : (
        <LoadingComponent />
      )}
    </MerchantsProvider>
  );
};
