import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import { I18nProvider } from "../_oxapay/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_oxapay/layout/core";
import { MasterInit } from "../_oxapay/layout/MasterInit";
import ShowAlertProvider from "./Core/ShowAlertContext";
import { getPageTitle } from "./services/pageTitle";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = `OxaPay | ${pageTitle}`;
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ErrorBoundary>
        <I18nProvider>
          <ShowAlertProvider>
            <LayoutProvider>
              <Outlet />
              <MasterInit />
            </LayoutProvider>
          </ShowAlertProvider>
        </I18nProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export { App };
