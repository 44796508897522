import React from "react";
import { SupportedCoinsRowOne } from "../main/SupportedCoinsRowOne";
import { SupportedCoinsRowTwo } from "../main/SupportedCoinsRowTwo";
import { SupportedCoinsRowThree } from "../main/SupportedCoinsRowThree";

interface props {
  reverse?: boolean;
}
export const SliderLogo = ({ reverse = false }: props) => {
  return (
    <div className="py-0 overflow-hidden h-md-300px h-150px">
      <div className="slider slider-content">
        <div
          className={`${
            reverse ? "slide-track-reverse" : "slide-track"
          } align-items-center flex-column w-auto`}
          style={{ margin: "0 auto" }}
        >
          <SupportedCoinsRowOne />
          <SupportedCoinsRowTwo />
          <SupportedCoinsRowThree />
          <SupportedCoinsRowOne />
          <SupportedCoinsRowTwo />
          <SupportedCoinsRowThree />
          <SupportedCoinsRowOne />
          <SupportedCoinsRowTwo />
          <SupportedCoinsRowThree />
        </div>
      </div>
    </div>
  );
};
