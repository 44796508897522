import React from "react";

interface props {
  children: string;
  className?: string;
  iconColor?: string;
  onClick?: () => void;
}
export const AppButton = ({
  children,
  className,
  iconColor = "white",
  onClick,
}: props) => {
  return (
    <button
      onClick={onClick}
      className={`btn fs-1 rounded-20px ihover ${className && className}`}
    >
      {children}{" "}
      <i className={`fa-solid fa-arrow-right fs-2 ${"text-" + iconColor}`}></i>
    </button>
  );
};
