import React, { useState } from "react";
import { CodeBlock } from "../CodeBlock";

interface codeContent {
  title: string;
  code: string;
  language: string;
}
interface props {
  tabContent: codeContent[];
  color?: string;
}
export const CodeTabsMaker = ({ tabContent, color = "gray-800" }: props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  return (
    <>
      <ul className="nav nav-tabs nav-line-tabs nav-stretch mb-3 fs-6 border-0 px-5">
        {tabContent.map((tab, index) => (
          <li className="nav-item" key={index}>
            <a
              className={`nav-link ${color && `text-${color}`} ${
                activeTab === index ? "active" : ""
              }`}
              data-bs-toggle="tab"
              href={`#kt_tab_pane_${index + 1}`}
              onClick={() => handleTabChange(index)}
            >
              {tab.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content mb-5" id="myTabContent">
        {tabContent.map((tab, index) => (
          <div
            className={`tab-pane fade ${
              activeTab === index ? "show active" : ""
            }`}
            id={`kt_tab_pane_${index + 1}`}
            role="tabpanel"
            key={index}
          >
            <CodeBlock
              className="rounded-20px"
              code={tab.code}
              language={tab.language as any}
            />
          </div>
        ))}
      </div>
    </>
  );
};
