import React, { useState } from "react";
import { merchantsSection } from "../../app/services/faqs";
import { AccordionMaker } from "./AccordionMaker";
import { CreateInvoiceMain } from "./CreateInvoiceMain";
import { MerchantStaticCode } from "./MerchantStaticCode";
import { MerchantWhiteLabelCode } from "./MerchantWhiteLabelCode";

export const MerchantAPISection = () => {
  const [activeSection, setActiveSection] = useState(0);
  return (
    <div className="p-5 container-fluid max-width-1550">
      <div className="row" style={{ minHeight: "900px" }}>
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <div className="mw-650px my-15">
            <h1 className="fs-4x text-dark fw-bold">Merchant API</h1>
            <p className="fs-3 text-gray-800 mb-15">
              The Merchant API offers developers a user-friendly interface to
              seamlessly incorporate OxaPay's cryptocurrency payment gateway
              into their websites, applications, or even Telegram bots.
            </p>
            <AccordionMaker
              content={merchantsSection}
              activeItem={(key) =>
                key !== null && setActiveSection(Number(key))
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
          {activeSection === 0 ? (
            <CreateInvoiceMain />
          ) : activeSection === 1 ? (
            <div className="w-100 overflow-hidden">
              <MerchantWhiteLabelCode />
            </div>
          ) : (
            <div className="w-100 overflow-hidden">
              <MerchantStaticCode light={false} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
