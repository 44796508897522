import React, { useState, useEffect, useRef } from "react";
import { KTSVG, toAbsoluteUrl } from "../_oxapay/helpers";

interface Option {
  value: string;
  label: string;
  image: string;
  disable?: boolean;
}

interface Props {
  className?: string;
  options: Option[];
  height?: number;
  value?: string;
  defaultValue: string;
  onChange: (value: string | undefined) => void;
  showOptionsStatus?: (status: boolean) => void;
}

const SelectWithImage: React.FC<Props> = ({
  className,
  defaultValue,
  value,
  options,
  height = 400,
  onChange,
  showOptionsStatus,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);
  const [selectedLabel, setSelectedLabel] = useState(
    options.find((option) => option.value === value)?.label || defaultValue
  );

  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectDropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    showOptionsStatus && showOptionsStatus(showOptions);
    if (showOptions && inputRef.current) {
      inputRef.current.focus();
    } // eslint-disable-next-line
  }, [showOptions]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectDropdownRef.current &&
        !selectDropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(value);
    if (value === undefined || value === "") {
      setSelectedLabel(defaultValue);
    } else {
      const label = options.find((option) => option.value === value)?.label;
      label && setSelectedLabel(label);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    onChange(selectedValue);
    // eslint-disable-next-line
  }, [selectedValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filtered);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find(
      (option) => option.value === event.target.value
    );
    setSelectedValue(event.target.value);
    setSelectedLabel(selectedOption ? selectedOption.label : "");
    setShowOptions(false);
  };

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
    setFilteredOptions(options);
  };

  return (
    <div ref={selectDropdownRef} className="select-with-images">
      <div className="select-container">
        <div
          className={`select2-selection select2-selection--single form-select form-control form-select-solid outline-none cursor-pointer ${className}`}
          onClick={handleToggleOptions}
        >
          <div className="select-option">
            {options.find((option) => option.value === selectedValue)?.image ? (
              <img
                src={
                  options.find((option) => option.value === selectedValue)
                    ?.image
                }
                alt="option"
              />
            ) : (
              ""
            )}
            {selectedLabel}
          </div>
        </div>
        {showOptions && (
          <div className="select2-dropdown select2-dropdown--below select-dropdown-with-image shadow rounded mt-1 pb-4">
            <div className="position-relative pt-3 pb-2 px-5">
              <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                <KTSVG
                  path="/media/icons/duotune/general/gen004.svg"
                  className="svg-icon-3"
                />
              </span>
              <input
                type="text"
                className="form-control w-100 fs-7 ps-12 mt-1 mb-2"
                placeholder="Search..."
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>
            <select
              value={selectedValue}
              onChange={handleSelectChange}
              ref={selectRef}
              style={{ display: "none" }}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className={`mh-${height}px overflow-auto`}>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <div
                    key={option.value}
                    className={`px-6 py-3 ${
                      option.value === selectedValue
                        ? "text-primary bg-light-primary"
                        : "text-gray-700 bg-hover-light-secondary"
                    } text-hover-primary ${option.disable && "disable"}`}
                    onClick={() => {
                      setSelectedValue(option.value);
                      setSelectedLabel(option.label);
                      setShowOptions(false);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {option.image && (
                          <img
                            className="h-25px me-2"
                            src={toAbsoluteUrl(option.image)}
                            alt={option.label}
                          />
                        )}
                        {option.label}
                      </div>
                      {option.value === selectedValue && (
                        <i className="fa-solid fa-check text-primary"></i>
                      )}
                      {option.disable && (
                        <span className="badge badge-light">Disabled</span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="select-no-options">Nothing found</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectWithImage;
