import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { SupportedCoins } from "../../../components/main/SupportedCoins";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { walletQuestions } from "../../services/faqs";

export const Wallet = () => {
  return (
    <>
      <GradientBackground
        className="px-5"
        color={"306c22"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          Keeps your crypto assets
          <br />
          safe in OxaPay
        </p>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0">
          Web wallet and Telegram Wallet
        </p>
      </GradientBackground>
      <SupportedCoins title="Manage +10 coins and Tokens in OxaPay" />
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#f9fafb" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                Why should I use OxaPay as wallet?
              </h1>
              <p className="fs-3 text-gray-800 mb-20">
                OxaPay offers a convenient solution for managing, safeguarding,
                and conducting transactions with over 10 major cryptocurrencies
                and tokens, including Bitcoin, Ethereum, Tron, Tether, and more.
                With OxaPay, you can securely store and transfer these digital
                assets, accessing them from anywhere, whether it's your desktop,
                mobile device, or even within your Telegram account. It's your
                ideal crypto wallet for seamless accessibility and peace of
                mind.
              </p>
              <h2 className="fs-2x text-dark fw-bold mb-7">
                Manage +10 coins and tokens
              </h2>
              <p className="fs-4 text-gray-800 mb-10">
                In the OxaPay wallet, you can effortlessly manage over 10
                different coins and tokens, including popular ones like Bitcoin,
                Ethereum, Tron and etc. The interface is user-friendly, ensuring
                a seamless experience while handling multiple digital assets
                simultaneously.
              </p>
              <h2 className="fs-2x text-dark fw-bold mb-7">
                Internal transaction with zero fee
              </h2>
              <p className="fs-4 text-gray-800 mb-10">
                When you transfer assets between OxaPay addresses, there's no
                need to worry about fees – it's completely free. Enjoy fee-free
                transfers between OxaPay addresses. Plus, if you have an OxaPay
                wallet, sharing it with others not only expands OxaPay community
                but also reduces your own transfer costs.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/wallet/oxapaywallet.png")}
                className="w-100 mw-600px my-10"
                alt="coin"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 pb-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px overflow-hidden">
          <div
            className="text-center m-auto my-5"
            style={{ maxWidth: "1000px" }}
          >
            <h1 className="fs-4x text-dark fw-bold">
              Key features of OxaPay wallet
            </h1>
            <p className="fs-3 text-gray-700 mb-15">
              Explore the essential features of the OxaPay wallet
            </p>
          </div>
          <div className="container mb-15">
            <div className="row justify-content-center align-items-stretch">
              <div className="col-12 col-md-2 my-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center py-10 rounded-35px"
                  style={{ backgroundColor: "#E0EBFF" }}
                >
                  <img
                    className="w-100px"
                    src={toAbsoluteUrl("media/landing/wallet/iwallet.svg")}
                    alt={"wallet icon"}
                  />
                  <p className="fs-3 fw-bold m-0" style={{ color: "#1A3F83" }}>
                    Zero fee in
                    <br /> Internal Txs
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-2 my-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center py-10 rounded-35px"
                  style={{ backgroundColor: "#FFF3E0" }}
                >
                  <img
                    className="w-100px"
                    src={toAbsoluteUrl("media/landing/wallet/icoins.svg")}
                    alt={"coins icon"}
                  />
                  <p className="fs-3 fw-bold m-0" style={{ color: "#6F480A" }}>
                    Support +10
                    <br />
                    coins and tokens
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-2 my-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center py-10 rounded-35px"
                  style={{ backgroundColor: "#FFE0DF" }}
                >
                  <img
                    className="w-100px"
                    src={toAbsoluteUrl("media/landing/wallet/iheart.svg")}
                    alt={"heart icon"}
                  />
                  <p
                    className="fs-3 fw-bold m-0 my-4"
                    style={{ color: "#ee1611" }}
                  >
                    Easy to Use
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-2 my-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center py-10 rounded-35px"
                  style={{ backgroundColor: "#E0EBFF" }}
                >
                  <img
                    className="w-100px"
                    src={toAbsoluteUrl("media/landing/wallet/isecurity.svg")}
                    alt={"security icon"}
                  />
                  <p
                    className="fs-3 fw-bold m-0 my-4"
                    style={{ color: "#1A3F83" }}
                  >
                    Security
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-2 my-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center py-10 rounded-35px"
                  style={{ backgroundColor: "#EBE0FF" }}
                >
                  <img
                    className="w-100px"
                    src={toAbsoluteUrl("media/landing/wallet/ispeed.svg")}
                    alt={"speed icon"}
                  />
                  <p className="fs-3 fw-bold m-0" style={{ color: "#8306A0" }}>
                    Quick
                    <br />
                    transactions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GradientBackground
        color={"225de1"}
        className={"px-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">
              Manage your assets in telegram
            </h1>
            <p className="fs-3 text-white mb-15">
              OxaPay has taken a significant step forward by introducing a
              webApp Wallet for Telegram. Thanks to this innovative integration,
              users can now access and manage their OxaPay wallets directly
              within the Telegram app, thereby streamlining the process of
              handling cryptocurrency assets. Moreover, Telegram users can
              effortlessly keep track of their assets, monitor transactions, and
              perform fund transfers, all within the familiar and user-friendly
              Telegram interface. This development represents a remarkable
              improvement in the world of cryptocurrency management.
            </p>
            <a
              href="https://oxapay.com/blog/oxapay-launches-wallet-webapp-for-telegram"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <AppButton className="btn-primary px-14 py-5">
                Read more
              </AppButton>
            </a>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <img
              src={toAbsoluteUrl("media/landing/wallet/telegramwallet.png")}
              className="w-100 mw-600px my-10"
              alt="coin"
            />
          </div>
        </div>
      </GradientBackground>
      <FAQSection
        about={"OxaPay wallet"}
        faqs={walletQuestions}
        questionsSize={"fs-3"}
      />
    </>
  );
};
