import React, { useEffect, useState } from "react";
import { AutoConvert } from "../../../components/AutoConvert";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import CoinCarousel from "../../../components/main/CoinCarousel";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { swapPairs } from "../../services/AppRequests";
import { errorHandler } from "../../services/errorHandler";
import { swapQuestions } from "../../services/faqs";
import { coins, coinsPageData } from "../../services/landingDatas";

export const Swap = () => {
  const [pairsData, setPairsData] =
    useState<Record<string, { min: string; pair: string }[]>>();
  const [coinsList, setCoinsList] = useState<string[]>([]);
  const [pairs, setPairs] = useState<{ from: string; to: string }[]>([]);
  const [activePair, setActivePair] = useState<{ from: string; to: string }>();

  const findIndex = (targetCurrency: string) => {
    return coinsList.indexOf(targetCurrency);
  };

  const convertData = (
    data: Record<string, { min: string; pair: string }[]>
  ) => {
    return Object.entries(data).flatMap(([crypto, pairs]) =>
      pairs.map((pairData) => ({
        from: crypto,
        to: pairData.pair,
      }))
    );
  };

  useEffect(() => {
    swapPairs()
      .then((res) => {
        if (res.data.status === 200) {
          setPairsData(res.data.data);
        }
      })
      .catch((error) => errorHandler(error));
  }, []);

  useEffect(() => {
    if (pairsData) {
      setCoinsList(Object.keys(pairsData));
      const extractedPairs = convertData(pairsData);
      setPairs(extractedPairs);
    }
  }, [pairsData]);

  useEffect(() => {
    if (pairs.length > 0) {
      let index = 0;
      const interval = setInterval(() => {
        if (pairs.length > 0) {
          setActivePair(pairs[index]);
          index = (index + 1) % pairs.length;
        }
      }, 3000); // Change activePair every 3 seconds

      return () => clearInterval(interval); // Cleanup on component unmount
    }
  }, [pairs]);

  return (
    <>
      <GradientBackground
        color={
          activePair
            ? coinsPageData[coins[activePair?.to]?.slug]?.color
            : coinsPageData["tether"].color
        }
        secondaryColor={
          activePair
            ? coinsPageData[coins[activePair?.from]?.slug]?.color
            : coinsPageData["bitcoin"]?.color
        }
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        {activePair && (
          <div className="position-relative swap-coin-container mt-5">
            <CoinCarousel
              coinSize="swap-coin-size"
              coinsList={coinsList}
              activeIndex={findIndex(activePair?.to)}
              className="position-absolute swap-coin-size rotate-90"
              style={{ left: "40%" }}
            />
            <CoinCarousel
              coinSize="swap-coin-size"
              coinsList={coinsList}
              activeIndex={findIndex(activePair?.from)}
              className="position-absolute swap-coin-size rotate-90"
              style={{ left: "15%" }}
            />
          </div>
        )}

        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          OxaPay Swap
        </p>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0 mw-800px">
          Experience seamless asset swapping with OxaPay's Swap feature. Simply
          access your OxaPay panel, head to the designated Swap page, choose
          your desired currencies, input the amounts for the swap, and witness
          instant, hassle-free swap.
        </p>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#F2F2F2" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">What is Swap?</h1>
              <p className="fs-3 text-gray-800 mb-15">
                A crypto swap refers to the exchange of one cryptocurrency for
                another without using a traditional exchange. Swapping allows
                users to trade their digital assets directly with each other,
                facilitated by automated systems. Swaps can involve various
                cryptocurrencies and tokens and are typically executed quickly
                and with minimal fees.
                <br />
                <br />
                OxaPay Swap, a powerful tool integrated into OxaPay, facilitates
                the seamless conversion of your crypto assets within the OxaPay
                ecosystem. This feature converts between different
                cryptocurrencies or tokens, offering users an effortless means
                to convert digital assets instantly. Our team dedicated efforts
                to crafting a user-friendly system, enhancing your swap
                experience for greater ease and convenience.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/swap/swap.png")}
                className="w-100 mw-600px my-10"
                alt="coin"
              />
            </div>
          </div>
        </div>
      </div>
      <AutoConvert />
      <div className="px-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#f9fafb" }}
        >
          <div
            className="text-center m-auto mt-20 mb-10"
            style={{ maxWidth: "1000px" }}
          >
            <h1 className="fs-3x text-dark fw-bold">How to Swap my assets?</h1>
            <p className="fs-3 text-gray-700 mb-15 text-center">
              Just follow three simple steps to swap. If you need help, feel
              free to contact your account manager.
            </p>
          </div>
          <div className="w-100 text-center">
            <img
              className="d-block d-lg-none mt-20 mb-10 mw-250px m-auto"
              alt="steps"
              src={toAbsoluteUrl("/media/landing/swap/swapsteps-v.svg")}
            />
            <img
              className="d-none d-lg-block mt-20 mb-10 m-auto"
              alt="steps"
              src={toAbsoluteUrl("/media/landing/swap/swapsteps-h.svg")}
              style={{ maxWidth: "1100px" }}
            />
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="gray-gradient justify-content-center align-items-center w-100 rounded-35px overflow-hidden">
          <div className="row">
            <div className="col-12 col-md-6">
              {activePair && (
                <div className="position-relative swap-coin-container-widget my-15">
                  <CoinCarousel
                    coinSize="swap-coin-size-widget"
                    coinsList={coinsList}
                    activeIndex={findIndex(activePair?.to)}
                    className="position-absolute swap-coin-size-widget rotate-90"
                    style={{ left: "40%" }}
                  />
                  <CoinCarousel
                    coinSize="swap-coin-size-widget"
                    coinsList={coinsList}
                    activeIndex={findIndex(activePair?.from)}
                    className="position-absolute swap-coin-size-widget rotate-90"
                    style={{ left: "15%" }}
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-start align-items-center">
              <div className="d-flex justfiy-content-center flex-column align-items-start px-7 py-12">
                <h1 className="fs-4x fw-bold">
                  More than{" "}
                  {pairs.length > 0 && pairs.length - (pairs.length % 10)} Swap
                  Pairs On OxaPay
                </h1>
                <a
                  className="ihover fs-3 text-dark d-flex align-items-center"
                  href="https://app.oxapay.com/swap"
                >
                  <span className="me-3">
                    Check supported pairs and swap instantly
                  </span>
                  <i className="fa-solid fa-arrow-right fs-2 text-dark"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQSection
        about={"Swap"}
        faqs={swapQuestions(
          pairs.map(
            (res, index) =>
              `<br/>${index + 1}. ${coins[res.from]?.name} to ${
                coins[res.to]?.name
              }`
          )
        )}
        questionsSize={"fs-3"}
      />
    </>
  );
};
