import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { PricingSkeleton } from "../../../components/main/PricingSkeleton";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { transferCost } from "../../services/AppRequests";
import { coins, servicesFee } from "../../services/landingDatas";

interface TransferConditions {
  coin: string;
  network: string;
  withdrawFee: number;
  withdrawMin: number;
  staticFixedFee: number;
  status: boolean;
}
export const Pricing = () => {
  const [trasnferConditions, setTransferConditions] =
    useState<TransferConditions[]>();
  const [fetching, setFetching] = useState<boolean>(true);

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    transferCost()
      .then((res: any) => {
        setFetching(false);
        if (res.data.result === 100) {
          const convertedData: TransferConditions[] = [];

          Object.keys(res.data.data).forEach((symbol) => {
            const coinData = res.data.data[symbol];
            const networks = coinData.networkList;

            Object.keys(networks).forEach((networkKey) => {
              const networkData = networks[networkKey];
              const convertedItem: TransferConditions = {
                coin: coinData.symbol,
                network: networkData.name,
                withdrawFee: networkData.withdrawFee,
                withdrawMin: networkData.withdrawMin,
                staticFixedFee: networkData.staticFixedFee,
                status: coinData.status,
              };
              convertedData.push(convertedItem);
            });
          });

          setTransferConditions(convertedData);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <GradientBackground
        color={"f4b405"}
        decoratives={
          <>
            <img
              className="d-none d-lg-block position-absolute pointer-event-none z-index-0 w-100 mw-500px"
              src={toAbsoluteUrl(`/media/landing/pricing/percent.png`)}
              style={{
                top: -158,
                right: 0,
                rotate: "-110deg",
                filter: "blur(10px)",
              }}
              alt={"design item"}
            />
            <img
              className="d-none d-lg-block position-absolute pointer-event-none z-index-0 w-100 mw-500px"
              src={toAbsoluteUrl(`/media/landing/pricing/percent.png`)}
              style={{
                bottom: -211,
                right: 250,
                rotate: "41deg",
                filter: "blur(10px)",
              }}
              alt={"design item"}
            />
            <AbsoluteCircle position="top-left" />
          </>
        }
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <img
              src={toAbsoluteUrl("/media/landing/pricing/percent.png")}
              className="w-100 mw-600px my-10"
              alt="coin"
            />
          </div>
          <div className="col-12 col-md-7">
            <p className="m-0 text-center text-white large-title fw-bold mb-5">
              Fees From 0.4%!
            </p>
            <p className="fs-md-1 fs-5 text-center text-secondary m-0">
              Profit as the lowest fees on the market and no hidden fee
            </p>
          </div>
        </div>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <div className="w-100 row justify-content-center align-items-center">
            <div className="col-12 col-md-5 d-flex justify-content-center flex-column align-items-center">
              <img
                src={toAbsoluteUrl("/media/landing/pricing/cardpercent.png")}
                className="w-100 mw-400px my-10"
                alt="coin"
              />
              <h1 className="fs-3x text-dark fw-bold mb-7">
                OxaPay Services Fee
              </h1>
              <p className="fs-3 text-gray-800 mb-15 mw-500px">
                All fees, depending on your business, can be reduced to 0.4%. If
                you need assistance, please contact your account manager.
              </p>
            </div>
            <div className="col-12 col-md-5">
              <div className="py-5">
                <table className="table table-row-bordered table-row-gray-300 gy-7 m-auto mw-500px">
                  <thead>
                    <tr className="fw-bolder fs-3 text-gray-800">
                      <th>Service Type</th>
                      <th>Fee</th>
                    </tr>
                  </thead>
                  <tbody className="fs-4">
                    {servicesFee.map((service, index) => (
                      <tr key={index}>
                        <td className="py-5">{service.serviceName}</td>
                        <td className="py-5">
                          {service.fee}{" "}
                          {service.serviceName ===
                            "Merchant (Static address)" && (
                            <>
                              <span>+ </span>
                              <span
                                className="text-primary fw-bold cursor-pointer"
                                onClick={() => setModalShow(true)}
                              >
                                Fixed Fee
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#f9fafb" }}
        >
          <div
            className="text-center m-auto mt-20 mb-10"
            style={{ maxWidth: "1000px" }}
          >
            <h1 className="fs-3x text-dark fw-bold">
              OxaPay Transfer Conditions
            </h1>
            <p className="fs-3 text-gray-700 mb-15 mw-700px text-start">
              The fee for internal assets transfer is zero but for transfer on
              blockchain is dependent on blockchain miners' fee.
            </p>
          </div>
          <div
            className="py-5 container-fluid mb-20 "
            style={{ maxWidth: "1500px" }}
          >
            <div className="table-responsive">
              <table className="table table-row-bordered table-row-gray-300 gy-7 m-auto">
                <thead>
                  <tr className="fw-bolder fs-2 text-gray-800">
                    <th className="min-w-150px">Coin</th>
                    <th className="min-w-150px">Network</th>
                    <th className="min-w-150px">Minimum</th>
                    <th className="min-w-150px">Fee</th>
                  </tr>
                </thead>
                <tbody className="fs-2">
                  {fetching && (
                    <>
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                      <PricingSkeleton />
                    </>
                  )}
                  {trasnferConditions &&
                    !fetching &&
                    trasnferConditions
                      .filter((item) => item.status === true)
                      .map((item, index) => (
                        <tr key={index}>
                          <td className="py-5">
                            <img
                              src={toAbsoluteUrl(
                                `media/svg/coins/${coins[item.coin]?.slug}.svg`
                              )}
                              className="h-50px me-2"
                              alt="coin"
                            />{" "}
                            <span className="fw-bold">
                              {coins[item.coin]?.name}
                            </span>
                          </td>
                          <td className="py-5">
                            <span className="fw-bold">{item.network}</span>
                          </td>
                          <td className="py-5">
                            {item.withdrawMin} {item.coin}
                          </td>
                          <td className="py-5">
                            {item.withdrawFee} {item.coin}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Header>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <h5 className="modal-title ms-5">Fixed Fee for Static Addresses</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              onClick={() => setModalShow(false)}
            >
              <span className="svg-icon svg-icon-2x">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="px-15">
          <div className="table-responsive">
            <table className="table table-row-bordered table-row-gray-300 gy-7 m-auto">
              <thead>
                <tr className="fw-bolder fs-4 text-gray-800">
                  <th className="min-w-150px">Coin</th>
                  <th className="min-w-150px">Network</th>
                  <th className="min-w-150px">Fixed fee</th>
                </tr>
              </thead>
              <tbody className="fs-2">
                {fetching && (
                  <>
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                    <PricingSkeleton count={3} />
                  </>
                )}
                {trasnferConditions &&
                  !fetching &&
                  trasnferConditions
                    .filter((item) => item.status === true)
                    .map((item, index) => (
                      <tr key={index}>
                        <td className="py-3">
                          <img
                            src={toAbsoluteUrl(
                              `media/svg/coins/${coins[item.coin]?.slug}.svg`
                            )}
                            className="h-40px me-2"
                            alt="coin"
                          />{" "}
                          <span className="fw-bold fs-4">
                            {coins[item.coin]?.name}
                          </span>
                        </td>
                        <td className="py-3">
                          <span className="fs-4">{item.network}</span>
                        </td>
                        <td className="py-3">
                          <span className="fs-4">
                            {item.staticFixedFee} {item.coin}
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
