import React from "react";
import Cookies from "universal-cookie";

interface props {
  onClick: () => void;
}
export const PolicyFooter = ({ onClick }: props) => {
  const cookies = new Cookies();
  const cookieAccepted = () =>
    cookies.set("cookie-accepted", true, { path: "/" });
  return (
    <div className="fixed-footer d-flex flex-md-row flex-column">
      <p className="banner-text py-3 w-md-50 px-8">
        We use cookies to ensure you get the best experience and analyze our
        traffic. By clicking on "I accept" button or using OxaPay, you agree to
        our{" "}
        <button
          className="btn-link"
          data-bs-toggle="modal"
          data-bs-target="#terms"
        >
          Terms
        </button>{" "}
        and{" "}
        <button
          className="btn-link"
          data-bs-toggle="modal"
          data-bs-target="#privacy"
        >
          Privacy Policy
        </button>
      </p>
      <div>
        <button
          className="btn btn-primary w-150px mx-10 my-3"
          onClick={() => {
            cookieAccepted();
            onClick();
          }}
        >
          I Accept
        </button>
      </div>
    </div>
  );
};
