import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Slide, toast } from "react-toastify";
import { errorHandler } from "../app/services/errorHandler";

interface props {
  wrap?: boolean;
  text: string;
  addonText?: string;
  className?: string;
  tooltipText?: string;
  qrCode?: boolean;
  copyIcon?: boolean;
  showAlert?: () => void;
}

export const TextCopyWidget = ({
  wrap = false,
  text,
  className,
  tooltipText = "Copy",
  addonText,
  qrCode = false,
  copyIcon = true,
  showAlert,
}: props) => {
  const [copied, setCopied] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (error) {
      // Clipboard API writeText failed, falling back to document.execCommand for compatibility
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      } catch (fallbackError) {
        // Unable to copy using Clipboard API and document.execCommand
        errorHandler(fallbackError as Error);
        toast.info("Clipboard copy failed", {
          toastId: "copyFailed",
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "dark",
          closeButton: true,
          closeOnClick: true,
          draggable: true,
          transition: Slide,
          icon: false,
        });
      }
    }
  };

  return (
    <>
      {copyIcon ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{copied ? "Copied" : tooltipText}</Tooltip>}
        >
          <span
            className={`cursor-pointer copy-hover p-1 rounded ${className}`}
            onClick={() => {
              copyToClipboard();
              showAlert && showAlert();
            }}
          >
            {text + (addonText ? " " + addonText : "")}
            {copied ? (
              <i className="fa-solid fa-check fs-5 text-success mx-1"></i>
            ) : (
              <i className="fa-regular fa-copy fs-5 mx-1"></i>
            )}
          </span>
        </OverlayTrigger>
      ) : (
        <span
          className={className}
          onClick={() => {
            copyToClipboard();
            showAlert && showAlert();
          }}
        >
          {wrap ? (
            <>
              {text.slice(0, Math.floor(text.length / 2))}
              <br />
              {text.slice(Math.floor(text.length / 2))}
            </>
          ) : (
            text
          )}
          {addonText ? " " + addonText : ""}
        </span>
      )}

      {qrCode && (
        <OverlayTrigger
          placement="top-end"
          overlay={
            <Tooltip>
              {qrLoading && (
                <div className="h-150px w-150px bg-secondary rounded m-auto shine my-1"></div>
              )}
              <img
                className={`mx-auto d-block my-1 mx-0 ${qrLoading && "d-none"}`}
                src={`http://api.qrserver.com/v1/create-qr-code/?data=${text}&size=150x150`}
                alt="link-qrcode"
                onLoad={() => {
                  setQrLoading(false);
                }}
              />
            </Tooltip>
          }
        >
          <i className="cursor-pointer fa-solid fa-qrcode fs-3"></i>
        </OverlayTrigger>
      )}
    </>
  );
};
