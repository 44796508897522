import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface Props {
  endtime: number;
  duration: number;
}

export const CircleProgressBar = ({ endtime, duration }: Props) => {
  const [progressValue, setProgressValue] = useState<number>();

  useEffect(() => {
    const updateProgress = () => {
      const remainingTime = endtime - Math.floor(Date.now() / 1000);
      const progress = Math.max(0, (remainingTime / duration) * 100);

      if (progress <= 0) {
        clearInterval(interval);
      } else {
        setProgressValue(progress);
      }
    };
    const interval = setInterval(updateProgress, 100);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [endtime]);

  return (
    <>
      {progressValue && (
        <div style={{ width: 25, height: 25 }}>
          <CircularProgressbar
            value={progressValue}
            strokeWidth={15}
            styles={buildStyles({
              rotation: 1,
              strokeLinecap: "butt",
              pathColor: `#3478f6`,
            })}
          />
        </div>
      )}
    </>
  );
};
