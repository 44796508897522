import React from "react";
import { Link } from "react-router-dom";
import { coins } from "../../app/services/landingDatas";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

export const SupportedCoinsRowThree = () => {
  return (
    <>
      <div className="slide my-3">
        {Object.values(coins)
          .filter((coin) => coin.status === true)
          .map(
            (coin, index) =>
              index >= 11 && (
                <Link key={index} to={`/currencies/${coin.slug}`}>
                  <img
                    src={toAbsoluteUrl(`/media/svg/coins/${coin.slug}.svg?v=1`)}
                    className="hover-scale h-30px h-lg-80px mx-3"
                    alt={`${coin.name} logo SVG OxaPay`}
                  />
                </Link>
              )
          )}
      </div>
    </>
  );
};
