export const alertsMapFetchPayment: { [key: string]: string } = {
  "-1": "The link is not valid (Invalid trackID)",
  "-2": "The link is not valid (Invalid type)",
  "-3": "The link is not valid (Invalid session)",
};

export const alertsMapStepOneDonate: { [key: string]: string } = {
  "-1": "The link is not valid (Invalid trackID)",
  "-2": "The link is not valid (Invalid type)",
  "-3": "The link is not valid (Invalid session)",
  "-10": "Name field is required, please enter a Name",
  "-11": "Email field is required, please enter a valid Email",
  "-12": "Entered currency is not valid",
  "-13": "Entered network is not valid",
  "-14":
    "Something went wrong in generating the address. Please try again or contact OxaPay support",
};

export const alertsMapSandbox: { [key: string]: string } = {
  "-1": "Please enter the Sandbox ID.",
  "-2": "Invalid sent status. Please provide a valid status (Success or Failure payment).",
  "-3": "Invalid Sandbox ID. Please check the entered ID.",
  "-4": "This payment has already been processed.",
};
