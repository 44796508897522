import { useNavigate } from "react-router-dom";
import { AutoConvert } from "../../../components/AutoConvert";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { CreateInvoiceMain } from "../../../components/main/CreateInvoiceMain";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { MerchantStaticCode } from "../../../components/main/MerchantStaticCode";
import { MerchantWhiteLabelCode } from "../../../components/main/MerchantWhiteLabelCode";
import { SupportedCoins } from "../../../components/main/SupportedCoins";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { merchantAPIQuestions } from "../../services/faqs";

export const MerchantAPI = () => {
  const navigate = useNavigate();
  return (
    <>
      <GradientBackground
        color={"7222ba"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <h1 className="m-0 text-center text-white large-title fw-bold mb-5 mw-1000px">
          Merchant API
        </h1>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0 mw-900px">
          The Merchant API offers developers a user-friendly interface to
          seamlessly incorporate OxaPay's cryptocurrency payment gateway into
          their websites, applications, or even Telegram bots.
        </p>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#e8e8e8" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/merchant/merchantkey.png")}
                className="w-100 mw-800px"
                alt="merchantkey"
              />
            </div>
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                How to generate a Merchant API key
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                A Merchant API key is essential for integrating your business
                with OxaPay's Crypto Merchant Api, enabling secure and
                straightforward cryptocurrency transactions.
              </p>
              <h2 className="fs-2x text-dark fw-bold mb-7">
                Steps to Create Your Merchant API Key
              </h2>
              <p className="fs-3 text-gray-800 mb-10 lh-lg">
                <b>Visit OxaPay:</b> Log into your account on the OxaPay
                website.
                <br />
                <b>Merchant API Page:</b> Click on the "Merchant API" page in
                the sidebar.
                <br />
                <b>Merchant Key Configuration:</b> Configure your key.
                <br />
                <b>Generate:</b> Click “generate merchant api key” to generate
                your key.
                <br />
                <b>Securely Store:</b> Copy and save your key safely for future
                use.
                <br />
                This process connects your platform with OxaPay's crypto payment
                solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 container-fluid max-width-1550">
        <div className="row" style={{ minHeight: "900px" }}>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <div className="mw-650px my-15">
              <h1 className="fs-3x text-dark fw-bold mb-7">Crypto Invoice</h1>
              <p className="fs-3 text-gray-800 mb-15">
                Creating a crypto invoice with OxaPay streamlines payment
                processes for various businesses, including retail, e-commerce,
                and service providers. This service simplifies accepting
                cryptocurrency, offering secure and transparent transactions.
                With its easy integration, businesses can expand their payment
                options, enhancing customer satisfaction and trust. OxaPay's
                crypto invoice service is designed to be user-friendly, ensuring
                a seamless experience for both merchants and customers. By
                adopting this innovative payment method, businesses can stay
                ahead in the digital economy. We encourage businesses to
                leverage this opportunity to tap into the growing cryptocurrency
                market.
              </p>
              <a
                href="https://docs.oxapay.com/api-reference/creating-an-invoice"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <AppButton className="btn-dark px-14 py-5">
                  Documentation
                </AppButton>
              </a>
              <AppButton
                onClick={() => navigate("/merchant-crypto-invoice")}
                className="px-14 py-5"
                iconColor="dark"
              >
                Read more
              </AppButton>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
            <CreateInvoiceMain />
          </div>
        </div>
      </div>
      <div className="px-5 pb-5">
        <div className="px-10 py-20 bg-gray-300 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px overflow-hidden">
          <div className="row w-100 justify-content-center align-items-center">
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <div className="d-flex flex-column overflow-hidden mt-20 mb-5 w-100 mw-600px">
                <MerchantWhiteLabelCode />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">White-Label</h1>
              <p className="fs-3 text-gray-800 mb-15">
                OxaPay's White-Label solution offers businesses, particularly in
                the finance, e-commerce, and technology sectors, an opportunity
                to seamlessly integrate crypto payments into their brand. This
                service allows for a customized payment gateway, aligning with
                your brand identity while ensuring transactions are secure and
                efficient. It's designed to enhance customer trust by providing
                a reliable, branded crypto payment experience. Ideal for
                businesses looking to enter or expand in the digital currency
                space, this solution encourages companies to adopt a
                forward-thinking approach to payments, leveraging the growing
                trend of cryptocurrency.
              </p>
              <a
                href="https://docs.oxapay.com/api-reference/creating-white-label-payment"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <AppButton className="btn-dark px-14 py-5">
                  Documentation
                </AppButton>
              </a>
              <AppButton
                onClick={() => navigate("/merchant-whitelabel")}
                className="px-14 py-5"
                iconColor="dark"
              >
                Read more
              </AppButton>
            </div>
          </div>
        </div>
      </div>

      <GradientBackground
        w100
        color={"225de1"}
        className={"px-5 pb-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">Static address</h1>
            <p className="fs-3 text-white mb-15">
              The Static Address Solution from OxaPay enables businesses,
              especially those in e-commerce and digital services, to offer a
              consistent crypto payment address for transactions. This approach
              simplifies the payment process for both the merchant and the
              customer, enhancing transaction efficiency and security. By using
              a static address, businesses can easily track payments and manage
              finances without the need for constant address updates. This
              service is ideal for companies looking to streamline their
              cryptocurrency payment methods and provide a more user-friendly
              experience. We encourage businesses interested in optimizing their
              payment infrastructure to consider this innovative solution.
            </p>
            <a
              href="https://docs.oxapay.com/api-reference/creating-static-wallet"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <AppButton className="btn-primary px-14 py-5">
                Documentation
              </AppButton>
            </a>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <div className="d-flex flex-column overflow-hidden mt-20 mb-5 w-100">
              <MerchantStaticCode />
            </div>
          </div>
        </div>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#c7ded3" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">Invoice Bot</h1>
              <p className="fs-3 text-gray-800 mb-10">
                The OxaPay Crypto Invoice Bot is engineered to simplify
                cryptocurrency transactions for businesses on the Telegram
                platform. With its ability to generate and manage invoices
                effortlessly, this tool is ideal for a wide array of businesses,
                from online ventures without websites to local storefronts. It
                features advanced customization options for precise control,
                ensuring transactions are both secure and straightforward.
                Businesses looking to more easily take advantage of crypto
                payments are encouraged to use this service, which supports an
                extensive range of cryptocurrencies.
              </p>
              <a
                href="https://t.me/oxapayInvoicebot"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <AppButton className="btn-success px-14 py-5">
                  oxapayInvoicebot
                </AppButton>
              </a>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/merchant/invoicebot.png")}
                className="w-100 mw-700px"
                alt="Auto Withdraw"
              />
            </div>
          </div>
        </div>
      </div>
      <AutoConvert />
      <div className="px-5 pb-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                Instantly Receive Payments in Your Personal Wallet
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                Auto Withdrawal allows you to receive your payment instantly in
                your personal wallet. To enable this feature, simply navigate to
                the merchant page in your account and either generate a new
                merchant key with Auto Withdrawal enabled or edit your existing
                merchant key and enable Auto Withdrawal.
                <br />
                <br />
                Your received currency will be sent to the address listed in
                your <b>Address List on the Settings page</b>. Alternatively, if
                no address is provided, your OxaPay balance will be charged. Go
                to the settings page from the left menu of your account and
                configure your addresses. Please note that to set or edit
                addresses, Two-Factor Authentication must be enabled for
                security.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/merchant/autowithdraw.png")}
                className="w-100 mw-700px"
                alt="Auto Withdraw"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{
            background: "#000",
            backgroundImage: `linear-gradient(45deg, #e24563 21%, #918a8d 86%)`,
          }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("/media/landing/merchant/analyse.png")}
                className="w-100 my-10"
                alt="coin"
              />
            </div>
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-light fw-bold mb-7 blend-mode-overlay">
                Transaction Analysis system
              </h1>
              <p className="fs-3 text-light mb-15">
                OxaPay's Crypto transaction Analysis system offers businesses
                powerful transaction history tools for analyzing cryptocurrency
                transactions. This service provides comprehensive insights and
                user-friendly tools for filtering and searching, facilitating
                the easy examination of merchant transactions. It's ideal for
                businesses seeking to optimize their crypto payment processes,
                offering clarity and control over financial activities. Designed
                for businesses of all sizes, it aids in making informed
                decisions by providing a clear understanding of payment trends
                and behaviors. Embrace this service to enhance your financial
                strategy and operational efficiency in the dynamic world of
                crypto payments.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SupportedCoins title="Accept +10 coins and Tokens in your business" />
      <FAQSection
        about={"Merchant API"}
        faqs={merchantAPIQuestions}
        questionsSize={"fs-3"}
      />
    </>
  );
};
