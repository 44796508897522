import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

interface Props {
  position: "top-left" | "bottom-right";
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  opacity?: number;
}

export const AbsoluteCircle = ({
  top = "-343px",
  bottom = "-399px",
  left = "-399px",
  right = "-343px",
  width = "922px",
  opacity = 0.2,
  position,
}: Props) => {
  const style: React.CSSProperties = {
    width: width,
    opacity: opacity,
  };

  if (position === "top-left") {
    style.top = top;
    style.left = left;
  } else if (position === "bottom-right") {
    style.bottom = bottom;
    style.right = right;
  }

  return (
    <img
      className="d-none d-lg-block position-absolute pointer-event-none z-index-0"
      src={toAbsoluteUrl(`/media/svg/shapes/circlepattern.svg`)}
      style={style}
      alt={"design item"}
    />
  );
};
