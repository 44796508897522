import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { SupportedCoins } from "../../../components/main/SupportedCoins";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { paymentLinkQuestions } from "../../services/faqs";

export const PaymentlinkPage = () => {
  return (
    <>
      <GradientBackground
        color={"000"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          Create an Invoice
          <br />
          Without Coding
        </p>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0">
          OxaPay Payment Link
        </p>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#f9fafb" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                What is Payment link?
              </h1>
              <p className="fs-3 text-gray-800 mb-15">
                Easily make payment links for your business needs without
                needing to know how to code. You can quickly share these links
                with your customers through Telegram, SMS, email, chat, or any
                other way you like, so they can pay you easily.
              </p>
              <h1 className="fs-3x text-dark fw-bold mb-7">
                How to create a payment link?
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                To make a payment link, all you need is an OxaPay account. Go to
                the payment link section in your account, fill in details like
                the amount, accepted coins, and other options. After that,
                you'll get a link. Share this link with others like you would an
                invoice.
              </p>
              <a
                href="https://docs.oxapay.com/integrations/payment-links"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <AppButton className="btn-dark px-14 py-5">
                  Full tutorial
                </AppButton>
              </a>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/paymentlink/plpreview.png")}
                className="w-100 mw-600px my-10"
                alt="coin"
              />
            </div>
          </div>
        </div>
      </div>
      <GradientBackground
        color={"345fbd"}
        className={"px-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">UnderPaid cover</h1>
            <p className="fs-3 text-white mb-15">
              Underpaid cover signifies that if the payer submits a payment that
              is slightly less (by an amount equal to or less than the underpaid
              amount), than the invoiced amount, the invoice will still be
              accepted.
            </p>
            <h1 className="fs-3x text-white fw-bold mb-7">Fee paid by payer</h1>
            <p className="fs-3 text-white mb-10">
              In the payment link forms, there's a feature called 'Fee Paid by
              Payer.' When enabled, it means your customer will take care of all
              the fees for this payment.
            </p>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <img
              src={toAbsoluteUrl("media/landing/paymentlink/plsettings.png")}
              className="w-100 mw-600px my-10"
              alt="coin"
            />
          </div>
        </div>
      </GradientBackground>
      <SupportedCoins title="Accept +10 coins and Tokens in your Invoices" />
      <FAQSection
        about={"Payment link"}
        faqs={paymentLinkQuestions}
        questionsSize={"fs-3"}
      />
    </>
  );
};
