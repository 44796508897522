import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";

export interface FAQ {
  q: string;
  a: string;
}
interface props {
  content: FAQ[];
  activeItem?: (key: AccordionEventKey) => void;
  questionsSize?: string;
}
export const AccordionMaker = ({
  content,
  activeItem,
  questionsSize = "fs-1",
}: props) => {
  return (
    <Accordion
      defaultActiveKey="0"
      onSelect={(key) => activeItem && activeItem(key)}
      flush
    >
      {content.map((item, index) => (
        <div key={index}>
          <Accordion.Item eventKey={index.toString()}>
            <Accordion.Header>
              <h3 className={`${questionsSize} text-gray-800 fw-bold mb-0`}>
                {item.q}
              </h3>
            </Accordion.Header>
            <Accordion.Body
              className="pt-0 fs-5 text-gray-800"
              dangerouslySetInnerHTML={{ __html: item.a }}
            ></Accordion.Body>
          </Accordion.Item>
          {index !== content.length - 1 && <hr className="m-0" />}
        </div>
      ))}
    </Accordion>
  );
};
