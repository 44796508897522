import React, { useEffect, useState } from "react";
import { blogArticles } from "../../app/services/AppRequests";
import { convertUTCToLocalTime } from "../../app/services/functionServices";
import { PostSkeleton } from "./PostSkeleton";

interface Post {
  firstParagraph: string;
  image: string;
  postDate: string;
  title: string;
  url: string;
}
export const Blog = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    blogArticles()
      .then((res: any) => {
        setPosts(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div
      className="p-5 container-fluid max-width-1550 d-flex align-items-center justify-content-center"
      style={{ minHeight: "1000px" }}
    >
      <div className="my-15 w-100">
        <div className="text-center m-auto" style={{ maxWidth: "1000px" }}>
          <h1 className="fs-4x text-dark fw-bold">Blog</h1>
          <p className="fs-3 text-gray-700 mb-15">
            Discover the OxaPay blog article to learn in-depth details about
            OxaPay and cryptocurrency payments.
          </p>
        </div>
        <div className="row">
          {loading && (
            <>
              <PostSkeleton />
              <PostSkeleton />
              <PostSkeleton />
            </>
          )}
          {posts &&
            !loading &&
            posts?.map((post, index) => (
              <div key={index} className="col-12 col-md-4">
                <div className="card-2 rounded-35px overflow-hidden p-3 mb-5">
                  <img
                    src={post?.image}
                    className="w-100 rounded-25px"
                    alt="article"
                  />
                  <div className="px-9 pt-10 pb-10">
                    <h1
                      className="fs-2 mb-7"
                      dangerouslySetInnerHTML={{
                        __html: post?.title,
                      }}
                    />
                    <p
                      className="fs-5 text-gray-700 m-0"
                      dangerouslySetInnerHTML={{
                        __html: truncateText(post?.firstParagraph, 293),
                      }}
                    />

                    <div className="row mt-10 align-items-center">
                      <div className="col-12 col-md-6">
                        <a
                          href={post?.url}
                          className="fs-3 text-dark cursor-pointer m-0 fw-semibold d-flex align-items-center ihover"
                          target="_blank"
                          rel="noreferrer"
                          aria-label={`Read more about ${post?.title}`}
                        >
                          <span className="screen-reader-text">
                            {post?.title}
                          </span>
                          <span className="me-2">Read more</span>
                          <i className="fa-solid fa-arrow-right fs-2 text-dark"></i>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 text-end">
                        <p className="fs-8 m-0">
                          {convertUTCToLocalTime(post?.postDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
