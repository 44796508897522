import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

export const PaidInvoice = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center py-15 h-400px">
      <div className="d-flex justify-content-center align-items-center mb-ios-22">
        <img
          className="w-50px me-4"
          src={toAbsoluteUrl(`/media/webapp/paidinvoice.svg`)}
          alt={"paid invoice"}
        />
        <span className="fs-1 fw-ios-700">Paid Invoice</span>
      </div>
      <p className="text-center fs-4">This invoice has already been paid</p>
    </div>
  );
};
