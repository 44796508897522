import React from "react";

export const BusinessesTypes = () => {
  return (
    <>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Mobile Applications
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Online Marketplaces
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Subscription Services
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Travel and Tourism
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Online Art Market
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          E-commerce and Retail
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Gaming and Entertainment
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Digital Goods and Services
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Technology and Software Services
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Professional Services
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          High-Value Goods and Services
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Marketing & Advertising
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Tech and Innovation-Led Sectors
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Mobile Applications
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Online Marketplaces
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Subscription Services
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Travel and Tourism
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Online Art Market
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          E-commerce and Retail
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Gaming and Entertainment
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Digital Goods and Services
        </p>
      </div>
      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Technology and Software Services
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Professional Services
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          High-Value Goods and Services
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Marketing & Advertising
        </p>
      </div>

      <div className="bg-transparent-dark rounded">
        <p className="text-white fs-4 m-0 py-3 px-7 nowrap">
          Tech and Innovation-Led Sectors
        </p>
      </div>
    </>
  );
};
