import React, { useState, useEffect } from "react";

interface props {
  textArray: string[];
  typingSpeed?: number;
  waitTime?: number;
}

const TypingText = ({
  textArray,
  typingSpeed = 75,
  waitTime = 2000,
}: props) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typeText = () => {
      if (isTyping) {
        if (currentText.length < textArray[currentTextIndex].length) {
          setCurrentText(
            textArray[currentTextIndex].slice(0, currentText.length + 1)
          );
        } else {
          setIsTyping(false);
          setTimeout(() => {
            setIsDeleting(true);
          }, waitTime); // Wait time before deleting
        }
      } else if (isDeleting) {
        if (currentText.length > 0) {
          setCurrentText(currentText.slice(0, currentText.length - 1));
        } else {
          setIsDeleting(false);
          const nextIndex = (currentTextIndex + 1) % textArray.length;
          setCurrentTextIndex(nextIndex);
          setTimeout(() => {
            setIsTyping(true);
          }, typingSpeed); // Delay before typing the next item
        }
      }
    };

    const typingInterval = setInterval(typeText, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [
    currentText,
    currentTextIndex,
    isTyping,
    isDeleting,
    textArray,
    waitTime,
    typingSpeed,
  ]);

  return (
    <>
      <span>{currentText}</span>
      <span className="blinking">|</span>
    </>
  );
};

export default TypingText;
