export const MerchantInvoiceSamples = [
  {
    title: "cURL",
    code: `curl -X POST https://api.oxapay.com/merchants/request \
-d '{
"merchant": "YOUR_MERCHANT_API_KEY",
"amount": 100,
"currency": "TRX",
"lifeTime": 30,
"feePaidByPayer": 0,
"underPaidCover": 2.5,
"callbackUrl": "https://example.com/callback",
"returnUrl": "https://example.com/success",
"description": "Order #12345",
"orderId": "ORD-12345",
"email": "customer@example.com"
}'`,
    language: "c",
  },
  {
    title: "php",
    code: `<?php

$url = 'https://api.oxapay.com/merchants/request';

$data = array(
    'merchant' => 'YOUR_MERCHANT_API_KEY',
    'amount' => 100,
    'currency' => 'TRX',
    'lifeTime' => 30,
    'feePaidByPayer' => 0,
    'underPaidCover' => 2.5,
    'callbackUrl' => 'https://example.com/callback',
    'returnUrl' => 'https://example.com/success',
    'description' => 'Order #12345',
    'orderId' => 'ORD-12345',
    'email' => 'customer@example.com'
);

$options = array(
    'http' => array(
        'header' => 'Content-Type: application/json',
        'method'  => 'POST',
        'content' => json_encode($data),
    ),
);

$context  = stream_context_create($options);
$response = file_get_contents($url, false, $context);
$result = json_decode($response);
var_dump($result);
?>`,
    language: "php",
  },
  {
    title: "Node.js",
    code: `const axios = require('axios');
const url = 'https://api.oxapay.com/merchants/request';
const data = JSON.stringify({
    merchant: 'YOUR_MERCHANT_API_KEY',
    amount: 100,
    currency: 'TRX',
    lifeTime: 30,
    feePaidByPayer: 0,
    underPaidCover: 2.5,
    callbackUrl: 'https://example.com/callback',
    returnUrl: 'https://example.com/success',
    description: 'Order #12345',
    orderId: 'ORD-12345',
    email: 'customer@example.com'
});

axios.post(url, data)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`,
    language: "javascript",
  },
  {
    title: "Python",
    code: `import requests
import json

url = 'https://api.oxapay.com/merchants/request'
data = {
    'merchant': 'YOUR_MERCHANT_API_KEY',
    'amount': 100,
    'currency': 'TRX',
    'lifeTime': 30,
    'feePaidByPayer': 0,
    'underPaidCover': 2.5,
    'callbackUrl': 'https://example.com/callback',
    'returnUrl': 'https://example.com/success',
    'description': 'Order #12345',
    'orderId': 'ORD-12345',
    'email': 'customer@example.com'
}

response = requests.post(url, data=json.dumps(data))
result = response.json()
print(result)`,
    language: "python",
  },
];

export const MerchantWhiteLabelSamples = [
  {
    title: "cURL",
    code: `curl -X POST https://api.oxapay.com/merchants/request/whitelabel \
-d '{
"merchant": "YOUR_MERCHANT_API_KEY",
"amount": 100,
"currency": "USD",
"payCurrency": "TRX",
"lifeTime": 90,
"feePaidByPayer": 1,
"underPaidCover": 10,
"callbackUrl": "https://example.com/callback",
"description": "Order #12345",
"orderId": "12345",
"email": "customer@example.com"
}'`,
    language: "c",
  },
  {
    title: "php",
    code: `<?php

$url = 'https://api.oxapay.com/merchants/request/whitelabel';

$data = array(
    'merchant' => 'YOUR_MERCHANT_API_KEY',
    'amount' => 100,
    'currency' => 'USD',
    'payCurrency' => 'TRX',
    'lifeTime' => 90,
    'feePaidByPayer' => 1,
    'underPaidCover' => 10,
    'callbackUrl' => 'https://example.com/callback',
    'description' => 'Order #12345',
    'orderId' => '12345',
    'email' => 'payer@example.com'
);

$options = array(
    'http' => array(
        'header' => 'Content-Type: application/json',
        'method'  => 'POST',
        'content' => json_encode($data),
    ),
);

$context  = stream_context_create($options);
$response = file_get_contents($url, false, $context);
$result = json_decode($response);
var_dump($result);
?>`,
    language: "php",
  },
  {
    title: "Node.js",
    code: `const axios = require('axios');
const url = 'https://api.oxapay.com/merchants/request/whitelabel';
const data = JSON.stringify({
    merchant: 'YOUR_MERCHANT_API_KEY',
    amount: 100,
    currency: 'USD',
    payCurrency: "TRX",
    lifeTime: 90,
    feePaidByPayer: 1,
    underPaidCover: 10,
    callbackUrl: 'https://example.com/callback',
    description: 'Order #12345',
    orderId: 'ORD-12345',
    email: 'customer@example.com'
});

axios.post(url, data)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`,
    language: "javascript",
  },
  {
    title: "Python",
    code: `import requests
import json

url = 'https://api.oxapay.com/merchants/request/whitelabel'
data = {
    'merchant': 'YOUR_MERCHANT_API_KEY',
    'amount': 100,
    'currency': 'USD',
    'payCurrency': 'TRX',
    'lifeTime': 90,
    'feePaidByPayer': 1,
    'underPaidCover': 10,
    'callbackUrl': 'https://example.com/callback',
    'description': 'Order #12345',
    'orderId': '12345',
    'email': 'payer@example.com'
}

response = requests.post(url, data=json.dumps(data))
result = response.json()
print(result)`,
    language: "python",
  },
];

export const MerchantStaticSamples = [
  {
    title: "cURL",
    code: `curl -X POST https://api.oxapay.com/merchants/request/staticaddress \
-d '{
"merchant": "YOUR_MERCHANT_API_KEY",
"currency": "BTC",
"callbackUrl": "https://example.com/callback"
}'`,
    language: "c",
  },
  {
    title: "php",
    code: `<?php

$url = 'https://api.oxapay.com/merchants/request/staticaddress';

$data = array(
    'merchant' => 'YOUR_MERCHANT_API_KEY',
    'currency' => 'BTC',
    'callbackUrl' => 'https://example.com/callback'
);

$options = array(
    'http' => array(
        'header' => 'Content-Type: application/json',
        'method'  => 'POST',
        'content' => json_encode($data),
    ),
);

$context  = stream_context_create($options);
$response = file_get_contents($url, false, $context);
$result = json_decode($response);
var_dump($result);
?>`,
    language: "php",
  },
  {
    title: "Node.js",
    code: `const axios = require('axios');
const url = 'https://api.oxapay.com/merchants/request/staticaddress';
const data = JSON.stringify({
    merchant: 'YOUR_MERCHANT_API_KEY',
    currency: 'BTC',
    callbackUrl: 'https://example.com/callback'
});

axios.post(url, data)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`,
    language: "javascript",
  },
  {
    title: "Python",
    code: `import requests
import json

url = 'https://api.oxapay.com/merchants/request/staticaddress'
data = {
    'merchant': 'YOUR_MERCHANT_API_KEY',
    'currency': 'BTC',
    'callbackUrl': 'https://example.com/callback'
}
response = requests.post(url, data=json.dumps(data))
result = response.json()
print(result)`,
    language: "python",
  },
];

export const PayoutAPISamples = [
  {
    title: "cURL",
    code: `curl -X POST https://api.oxapay.com/api/send \
-d '{
"key": "YOUR_PAYOUT_API_KEY",
"address": "RECEIVER_CRYPTO_ADDRESS",
"amount": 0.5,
"currency": "BTC",
"callbackUrl": "https://example.com/callback"
}'`,
    language: "c",
  },
  {
    title: "php",
    code: `<?php

$url = 'https://api.oxapay.com/api/send';

$data = array(
'key' => 'YOUR_PAYOUT_API_KEY',
'address' => 'RECEIVER_CRYPTO_ADDRESS',
'amount' => 0.5,
'currency' => 'BTC',
'callbackUrl' => 'https://example.com/callback'
);

$options = array(
'http' => array(
    'header' => 'Content-Type: application/json',
    'method'  => 'POST',
    'content' => json_encode($data),
),
);

$context  = stream_context_create($options);
$response = file_get_contents($url, false, $context);
$result = json_decode($response);
var_dump($result);
?>`,
    language: "php",
  },
  {
    title: "Node.js",
    code: `const axios = require('axios');
const url = 'https://api.oxapay.com/api/send';
const data = {
key: 'YOUR_PAYOUT_API_KEY',
address: 'RECEIVER_CRYPTO_ADDRESS',
amount: 0.5,
currency: 'BTC',
callbackUrl: 'https://example.com/callback'
};

axios.post(url, data)
.then(response => {
console.log(response.data);
})
.catch(error => {
console.error(error.response.data);
});`,
    language: "javascript",
  },
  {
    title: "Python",
    code: `import requests
import json

url = 'https://api.oxapay.com/api/send'
data = {
    'key': 'YOUR_PAYOUT_API_KEY',
    'address': 'RECEIVER_CRYPTO_ADDRESS',
    'amount': 0.5,
    'currency': 'BTC',
    'callbackUrl': 'https://example.com/callback'
}
response = requests.post(url, data=json.dumps(data))
result = response.json()
print(result)`,
    language: "python",
  },
];
