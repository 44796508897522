import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { NetworkOption } from "../paymentlink/PaymentlinkStepOne";
import SelectWithImage from "../SelectWithImage";
import {
  alertsMapFetchPayment,
  alertsMapStepOneDonate,
} from "../../app/services/alertsMap";
import { formatAmount } from "../../app/services/functionServices";
import { WaitButtonSpan } from "../WaitButtonSpan";

interface props {
  setActiveIndex: (activeIndex: number) => void;
  showOptionsStatus: (status: boolean) => void;
}

export const MerchantStepOne = ({
  setActiveIndex,
  showOptionsStatus,
}: props) => {
  const [fetching, setFetching] = useState(true);
  const [fetchingAmount, setFetchingAmount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<string>();
  const { fetchPayment, stepOnePayment, fetchAmount } = useRequest();
  const [networks, setNetworks] = useState<NetworkOption[] | undefined>();
  const [coinIsOpen, setCoinIsOpen] = useState<boolean>(false);
  const [networkIsOpen, setNetworkIsOpen] = useState<boolean>(false);
  const { id } = useParams();

  const cookies = new Cookies();
  const navigate = useNavigate();

  const {
    setExpireAt,
    setExpired,
    price,
    setPrice,
    coinsListMap,
    setCoinsListMap,
    merchantsSession,
    setMerchantsSession,
    setLogo,
    currency,
    setCurrency,
  } = useContext(MerchantsContext);

  useEffect(() => {
    coinIsOpen || networkIsOpen
      ? showOptionsStatus(true)
      : showOptionsStatus(false);
    // eslint-disable-next-line
  }, [coinIsOpen, networkIsOpen]);

  useEffect(() => {
    const referrer = document.referrer;
    fetchPayment(id as string, "merchant", "", referrer)
      .then((res) => {
        if (res.status === 200) {
          if (Number(res.session.step) === 4) {
            setActiveIndex(5);
          } else {
            setExpireAt(res.expireAt);
            setActiveIndex(Number(res.session.step) - 1);
            setCoinsListMap(res.coins);
            setLogo(res.logo);
            setCurrency(res.currency);
            setMerchantsSession(res.session);
            setPrice(res.price);
            formik.setValues({
              coin: res.session.coin,
              network: res.session.network,
            });
            const ref = cookies.get("ref");
            !ref &&
              cookies.set("ref", res.refId, {
                domain: ".oxapay.com",
                path: "/",
              });
            setFetching(false);
          }
        } else if (res.status === 0 && res.errorNum) {
          if (res.errorNum === -103) {
            setExpired(true);
          } else {
            navigate("/error/404");
          }
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      coin: "",
      network: "",
    },
    validationSchema: Yup.object({
      coin: Yup.string().required("Currency is require"),
      network: Yup.string().required("Network is require"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (
        values.coin === merchantsSession?.coin &&
        values.network === merchantsSession.network
      ) {
        setActiveIndex(Number(merchantsSession.step) - 1);
      } else {
        setLoading(true);
        setSubmitting(true);
        try {
          const res = await stepOnePayment(
            id ?? "",
            "merchant",
            "",
            values.coin,
            values.network,
            "",
            ""
          );
          if (res.status === 200) {
            setActiveIndex(res.session.step - 1);
            setMerchantsSession(res.session);
          } else {
            FailedModalFire(alertsMapStepOneDonate[res.errorNum.toString()]);
          }
        } catch (error) {
          errorHandler(error as Error);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      }
    },
  });

  const optionsCoin =
    coinsListMap &&
    Object.entries(coinsListMap)
      .filter(([_symbol, coin]) => coin.status === true)
      .map(([symbol, coin]) => ({
        value: symbol,
        label: `${coin.name} (${symbol})`,
        image: `/media/svg/coins/${coin?.slug}.svg`,
      }));

  useEffect(() => {
    if (formik.values.coin && coinsListMap) {
      setFetchingAmount(true);
      fetchAmount(id as string, "merchant", formik.values.coin)
        .then((res) => {
          if (res.status === 200) {
            setAmount(res.amount);
            setFetchingAmount(false);
          } else if (res.status === 0 && res.errorNum) {
            FailedModalFire(alertsMapFetchPayment[res.errorNum.toString()]);
          }
        })
        .catch((error) => {
          errorHandler(error as Error);
        });

      const Coin = coinsListMap[formik.values.coin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const optionsNetwork = Object.entries(networks).map(
          ([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.depositEnable,
            isMain: network?.isMain,
          })
        );
        setNetworks(optionsNetwork);
      }
    }
    formik.setFieldValue("network", "");
    // eslint-disable-next-line
  }, [formik.values.coin]);

  useEffect(() => {
    formik.setFieldValue("network", formik.values.network);
    // eslint-disable-next-line
  }, [formik.values.network]);

  useEffect(() => {
    networks?.length === 1 &&
      networks[0].isMain &&
      !networks[0].disable &&
      formik.setFieldValue("network", networks[0].value);
    // eslint-disable-next-line
  }, [networks]);

  return (
    <>
      <div className="card-header align-items-center px-10 justify-content-start">
        <h3 className="card-title">
          Amount to pay:
          {fetching ? (
            <div className="h-20px w-75px bg-secondary rounded shine ms-2 text-left"></div>
          ) : currency ? (
            <span className="ms-2">{price + " " + currency}</span>
          ) : (
            price && (
              <span className="ms-2">${Number(price).toLocaleString()}</span>
            )
          )}
        </h3>
      </div>
      {fetching ? (
        <div className="px-10 pb-0 pt-10 overflow-item">
          <div className="mb-5">
            <div className="h-20px w-25 bg-secondary rounded shine mb-2 text-left"></div>
            <div className="h-45px w-100 bg-secondary rounded m-auto shine"></div>
          </div>
          <div className="mb-10">
            <div className="h-20px w-25 bg-secondary rounded shine mb-2 text-left"></div>
            <div className="h-45px w-100 bg-secondary rounded m-auto shine"></div>
          </div>
          <div className="h-45px w-100 bg-secondary rounded m-auto shine mb-10"></div>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="px-10 pb-0 pt-10 overflow-item">
            {optionsCoin && (
              <>
                <label className="form-label text-start">Choose currency</label>
                <div className="position-relative mb-5">
                  <SelectWithImage
                    height={200}
                    showOptionsStatus={(status) => setCoinIsOpen(status)}
                    className={`${
                      formik.errors.coin &&
                      formik.touched.coin &&
                      "border border-danger"
                    }`}
                    defaultValue={"Choose currency"}
                    options={optionsCoin}
                    value={formik.values.coin}
                    onChange={(value) => {
                      formik.setFieldValue("coin", value);
                    }}
                  />
                  {formik.values.coin && (
                    <p className="mb-10 ms-3 mt-1 d-flex align-items-center">
                      Amount to pay:
                      {fetchingAmount ? (
                        <span className="h-15px w-75px bg-secondary rounded shine ms-1 text-left"></span>
                      ) : (
                        <span className="text-primary fw-bolder ms-1">
                          {amount &&
                            formatAmount(
                              parseFloat(amount),
                              coinsListMap[formik.values.coin]?.displayPrecision
                            )}{" "}
                          {formik.values.coin}
                        </span>
                      )}
                    </p>
                  )}

                  {formik.errors.coin && formik.touched.coin && (
                    <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                      {formik.errors.coin}
                    </p>
                  )}
                </div>
              </>
            )}
            {networks &&
              !(
                networks.length === 1 &&
                networks[0].isMain &&
                !networks[0].disable
              ) && (
                <>
                  <label className="form-label text-start">
                    Choose network
                  </label>
                  <div className="position-relative mb-10">
                    <SelectWithImage
                      showOptionsStatus={(status) => setNetworkIsOpen(status)}
                      className={`${
                        formik.errors.network &&
                        formik.touched.network &&
                        "border border-danger"
                      }`}
                      options={networks}
                      defaultValue={"Choose network"}
                      value={formik.values.network}
                      onChange={(network) =>
                        formik.setFieldValue("network", network)
                      }
                    />
                    {formik.errors.network && formik.touched.network && (
                      <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                        {formik.errors.network}
                      </p>
                    )}
                  </div>
                </>
              )}
          </div>
          {merchantsSession && (
            <div className="px-10 mb-10">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={formik.isSubmitting}
              >
                {loading ? <WaitButtonSpan /> : "Proceed to payment"}
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
};
