import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

export const QrDataWalletSuggest = () => {
  return (
    <div className="p-3">
      <p>
        If you pay this invoice using{" "}
        <a href="https://t.me/OxaPayWalletBot" target={"blank"}>
          <span className="fw-bold text-primary">OxaPay Telegram Wallet</span>
        </a>
        , there are no network fee incurred.
      </p>
      <a href="https://t.me/OxaPayWalletBot" target={"blank"}>
        <div
          className="bg-primary shine d-inline-block"
          style={{ padding: "5px", borderRadius: "12px" }}
        >
          <img
            src={toAbsoluteUrl("/media/webapp/oxapay-wallet.svg")}
            className="w-40px"
            alt="oxapay wallet logo"
          />
          <span className="ms-2 me-2 fw-bold text-white">
            OxaPay Telegram Wallet
          </span>
        </div>
      </a>
    </div>
  );
};
