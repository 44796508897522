import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { errorHandler } from "../../app/services/errorHandler";
import { useRequest } from "../../app/services/userHttpRequests";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { IconArrowIOS } from "../staticIcons/IconArrowIOS";
import { WebappCoinsSkeleton } from "../WebappCoinsSkeleton";
import { WebappNothingFound } from "../WebappNothingFound";

interface props {
  onSelect: (step: number) => void;
  setActiveIndex: (activeIndex: number) => void;
}

export interface Option {
  label: string;
  value: string;
  image: string;
  disable?: boolean;
}

export const MerchantWebappStepOneCoin = ({
  onSelect,
  setActiveIndex,
}: props) => {
  const [fetching, setFetching] = useState(true);
  const { fetchPayment } = useRequest();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    setPrice,
    coinsListMap,
    setCoinsListMap,
    setMerchantsSession,
    setSelectedCoin,
    setSelectedNetwork,
    setCurrency,
    setExpired,
    setExpireAt,
  } = useContext(MerchantsContext);

  useEffect(() => {
    fetchPayment(id as string, "merchant", "", "")
      .then((res) => {
        if (res.status === 200) {
          if (Number(res.session.step) === 4) {
            setActiveIndex(6);
          } else {
            setActiveIndex(
              res.session.step === "1" ? 0 : Number(res.session.step)
            );
            res.session.coin && setSelectedCoin(res.session.coin);
            res.session.network && setSelectedNetwork(res.session.network);
            setCoinsListMap(res.coins);
            setMerchantsSession(res.session);
            setCurrency(res.currency);
            setPrice(res.price);
            setFetching(false);
            setExpireAt(res.expireAt);
          }
        } else if (res.status === 0 && res.errorNum) {
          if (res.errorNum === -103) {
            setExpired(true);
          } else {
            navigate("/error/404");
          }
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  const optionsCoin =
    coinsListMap &&
    Object.entries(coinsListMap)
      .filter(([_symbol, coin]) => coin.status === true)
      .map(([symbol, coin]) => ({
        value: symbol,
        label: `${coin.name} (${symbol})`,
        image: `${coin?.slug}`,
      }));

  const optionsNetwork = (coin: string) => {
    // Mapping over entries of the networkList for the given coin
    const options = Object.entries(coinsListMap[coin].networkList).map(
      ([symbol, network]) => ({
        value: symbol,
        isMain: network.isMain,
        disable: !network.depositEnable,
      })
    );
    // Returning the array of options
    return options;
  };

  return (
    <>
      <div className="mt-20 d-flex justify-content-center align-items-center mb-ios-22">
        <img
          alt="coinIcon"
          className="w-ios-50px me-ios-15px"
          src={toAbsoluteUrl("/media/webapp/coin-to-pay.svg")}
        />
        <span className="fs-ios-34 fw-ios-700">Coin to Pay</span>
      </div>
      <p className="text-center fs-ios-17 mb-ios-46">
        Choose a coin to pay the invoice
      </p>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p
          className="text-ios-light fs-ios-13 ml-ios-17 mb-0 text-uppercase"
          style={{ width: "360px" }}
        >
          Choose a coin
        </p>
        <div
          className="bg-ios-light rounded-ios py-ios-12 pl-ios-16 mt-ios-9"
          style={{ width: "360px" }}
        >
          {fetching ? (
            <WebappCoinsSkeleton />
          ) : optionsCoin.length ? (
            optionsCoin.map((item, index) => (
              <div key={index}>
                <div
                  className="d-flex justify-content-between cursor-pointer"
                  onClick={() => {
                    if (
                      optionsNetwork(item.value).length === 1 &&
                      optionsNetwork(item.value)[0].isMain &&
                      !optionsNetwork(item.value)[0].disable
                    ) {
                      setSelectedNetwork(optionsNetwork(item.value)[0].value);
                      onSelect(2);
                    } else {
                      onSelect(1);
                      setSelectedNetwork("");
                    }
                    setSelectedCoin(item.value);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={toAbsoluteUrl(`/media/svg/coins/${item.image}.svg`)}
                      className="mr-ios-15"
                      width="30"
                      alt="coin"
                    />
                    <span className="fs-ios-17">{item.label}</span>
                  </div>
                  <IconArrowIOS />
                </div>
                {index !== optionsCoin.length - 1 && (
                  <div
                    className="separator separator-ios my-ios-10 ml-ios-42"
                    style={{ width: "calc(100% - 42px)" }}
                  ></div>
                )}
              </div>
            ))
          ) : (
            <WebappNothingFound />
          )}
        </div>
      </div>
    </>
  );
};
