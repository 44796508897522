import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { BlockConfirm } from "../BlockAnimate/BlockConfirm";
import { TextCopyWidget } from "../TextCopyWidget";

interface props {
  activeStep: number;
  setActiveIndex: (activeIndex: number) => void;
}

export const MerchantWebappStepThree = ({
  activeStep,
  setActiveIndex,
}: props) => {
  const { merchantsSession } = useContext(MerchantsContext);
  const { id } = useParams();
  useEffect(() => {
    Number(merchantsSession?.step) === 4 && setActiveIndex(4);
    // eslint-disable-next-line
  }, [merchantsSession?.step]);

  return (
    <>
      <div className="mt-15 mb-10 w-400px">
        {activeStep >= 3 && <BlockConfirm />}
      </div>
      <div>
        <p className="fw-bolder text-dark fs-ios-22 mb-ios-12">
          Confirming on blockchain
        </p>
        <div className="mt-2 mb-15">
          <span className="fs-ios-17 badge badge-light-primary badge-square badge-lg px-3 py-3 me-2">
            {merchantsSession?.confirm} / {merchantsSession?.confirmRequired}
          </span>
          <span className="fs-4 fw-semibold text-primary">Confirms</span>
          <div>
            {id && (
              <p className=" rounded px-2 d-block py-1 fs-7 fw-semibold text-secondary-500 mb-0 mt-10">
                Transaction Track ID:{" "}
                <TextCopyWidget
                  text={`${id}`}
                  tooltipText="Copy Track ID"
                  className="fs-5 mb-2 mt-7 fm-jetbrain"
                />
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
