import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { referralProgramQuestions } from "../../services/faqs";

export const ReferralsPlan = () => {
  return (
    <>
      <GradientBackground
        color={"5ec5e1"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          Get 30% Referrals
          <br />
          Commission
        </p>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0">
          Share your referral link and
          <br />
          get 30% referrals commission
        </p>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#f9fafb" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                OxaPay referrals commission
              </h1>
              <p className="fs-3 text-gray-800 mb-15">
                OxaPay offers a referral program where users can share their
                unique referral link and earn a commission of 30% on each
                payment transaction made by their referrals. This means that if
                you refer someone who uses OxaPay’s merchant crypto gateway, you
                will receive a commission of 30% on each transaction fee. By
                referring more people to OxaPay, you can earn more rewards. Sign
                up today and start referring your network to enjoy the benefits
                of our referral program.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl(
                  "media/landing/referrals/referralcommission.png"
                )}
                className="w-100 mw-600px my-10"
                alt="coin"
              />
            </div>
          </div>
        </div>
      </div>
      <GradientBackground
        color={"81997a"}
        className={"px-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">Referral +</h1>
            <p className="fs-3 text-white mb-15">
              If you create an invoice, be it a payment link, donation, or
              merchant transaction, and the person you send it to becomes a
              registered user on OxaPay, they will be considered your referral.
              When they use OxaPay for their transactions, you'll receive 30% of
              the fees they pay. For further details, click 'Read More'.
            </p>
            <a
              href="https://oxapay.com/blog/oxapay-referral-plus/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <AppButton className="btn-success px-14 py-5">
                Read More
              </AppButton>
            </a>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <img
              src={toAbsoluteUrl("media/landing/referrals/referralplus.svg")}
              className="w-100 mw-600px my-10"
              alt="coin"
            />
          </div>
        </div>
      </GradientBackground>
      <FAQSection
        about={"Affiliate program"}
        faqs={referralProgramQuestions}
        questionsSize={"fs-3"}
      />
    </>
  );
};
