import React from "react";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { BusinessesTypes } from "../../../components/main/BusinessesTypes";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { MerchantInvoiceCode } from "../../../components/main/MerchantInvoiceCode";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { oxapayCryptoWhitelabel } from "../../services/faqs";

export const MerchantCryptoWhitelabel = () => {
  return (
    <>
      <div className="px-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center blue-lightblue-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <AbsoluteCircle position="top-left" />
          <AbsoluteCircle position="bottom-right" />
          <div className="z-index-1">
            <p className="fs-1 text-center text-secondary m-0 mb-3 d-lg-block">
              Enhance Your Brand
            </p>
            <h1 className="m-0 text-center text-white large-title fw-bold mb-5">
              White Label Crypto
              <br />
              Payment Gateway
            </h1>
            <p className="fs-3 text-center text-secondary m-0 mb-9">
              Integrate top crypto payment solutions easily. Customize for brand
              uniqueness, ensuring security,
              <br /> efficiency, and user-friendliness with OxaPay white label
              Payment gateway.
            </p>
            <p className="py-3 px-7 rounded bg-blur fs-md-1 fs-5 text-center text-secondary m-0">
              Efficient Payment Processing, Seamless Integration, Customization
              and Branding, without KYC/KYB, Global Accessibility and
              Multi-Currency Support
            </p>
          </div>
        </div>
      </div>
      <div className="p-5 container-fluid max-width-1550">
        <div
          className="py-20 my-20 m-auto my-10"
          style={{ maxWidth: "1200px" }}
        >
          <h1 className="fs-3x text-dark fw-bold mb-7">
            What is a white label crypto payment gateway?
          </h1>
          <p className="fs-3 text-gray-800">
            A white label crypto payment gateway offers businesses a
            customizable, ready-to-launch payment solution under their own
            brand. It simplifies accepting cryptocurrencies, providing a
            seamless blend of security, efficiency, and brand integration. This
            service allows businesses to leverage the growing demand for crypto
            payments without the complexity of developing a proprietary system,
            enhancing their market presence and customer trust through a fully
            branded payment experience.
            <br />
            <br />
            OxaPay's White Label Payment Gateway solution , powered by its White
            Label API, offers businesses a customizable, brand-aligned
            cryptocurrency payment solution. This service simplifies adopting
            crypto payments, providing secure, efficient transactions across a
            wide cryptocurrency range. With OxaPay, companies enhance their
            digital presence and customer trust through a seamless, branded
            payment experience, without the complexity of developing their own
            system.
          </p>
        </div>
      </div>
      <div className="px-5">
        <div className="dark-gray-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <AbsoluteCircle position="top-left" />
          <div
            className="py-20 px-5 my-20 m-auto mt-10"
            style={{ maxWidth: "1200px" }}
          >
            <h1 className="fs-4x text-white mb-2">
              OxaPay white label payment gateway
            </h1>
            <p className="text-gray-700 mb-7 fs-1 badge badge-secondary mb-10">
              What businesses can use this service?
            </p>

            <p className="fs-3 text-white">
              OxaPay's White Label Payment Gateway is designed for businesses
              prioritizing brand consistency and customer trust in the crypto
              space. Ideal for fintech startups, online gaming platforms, and
              international e-commerce sites, this service offers a blend of
              customization, security, and global currency acceptance crucial
              for these sectors. Financial service providers and digital content
              platforms will also find value in OxaPay's solution, enabling them
              to offer secure, branded crypto transactions, catering to a
              tech-savvy, privacy-conscious audience.This solution is perfect
              for any business aiming to adopt cryptocurrency payments while
              maintaining a consistent brand identity.
            </p>
          </div>
          <div className="py-0 overflow-hidden">
            <div className="slider slider-content">
              <div className="slide-track-h align-items-center flex-column w-auto m-auto my-5">
                <div className="d-flex gap-3 flex-row">
                  <BusinessesTypes />
                </div>
              </div>
              <div className="slide-track-reverse-h align-items-center flex-column w-auto m-auto my-5">
                <div className="d-flex gap-3 flex-row">
                  <BusinessesTypes />
                </div>
              </div>
              <div className="slide-track-h align-items-center flex-column w-auto m-auto my-5">
                <div className="d-flex gap-3 flex-row">
                  <BusinessesTypes />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5 container-fluid max-width-1550">
        <div className="py-20 mt-20 mb-5 m-auto" style={{ maxWidth: "1200px" }}>
          <h1 className="fs-3x text-dark fw-bold mb-20">Why Choose OxaPay?</h1>

          <div className="row">
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/auto-convert-gray.svg"
                )}
                className="mh-75px mb-5"
                alt="aut-convert"
              />
              <h1 className="fs-1x text-dark fw-bold">
                1. Ability to Auto-Convert to USDT
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Automatically convert cryptocurrencies to USDT to stabilize your
                revenue against crypto market volatility.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/auto-withdraw.svg"
                )}
                className="mh-75px mb-5"
                alt="aut-withdraw"
              />
              <h1 className="fs-1x text-dark fw-bold">
                2. Instant Settlements and Automated Withdrawals
              </h1>
              <p className="fs-3 text-dark mw-550px">
                OxaPay enhances cash flow with instant settlements and automated
                withdrawals, streamlining operations for swift, hassle-free
                financial management.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/bitcoin.svg"
                )}
                className="mh-75px mb-5"
                alt="bitcoin-logo"
              />
              <h1 className="fs-1x text-dark fw-bold">
                3. Multi-Currency Support
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Accept a variety of cryptocurrencies, catering to a global
                customer base with diverse payment preferences.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl("/media/landing/merchant-invoice/stats.svg")}
                className="mh-75px mb-5"
                alt="statistics"
              />
              <h1 className="fs-1x text-dark fw-bold">
                4. Analytics and Reporting Tools
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Gain insights into your transactions with comprehensive
                analytics, helping to inform business decisions.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/support.svg"
                )}
                className="mh-75px mb-5"
                alt="support"
              />
              <h1 className="fs-1x text-dark fw-bold">
                5. 24/7 Customer Support
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Access round-the-clock support for any technical or customer
                service inquiries, ensuring your operations run smoothly.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/branding.svg"
                )}
                className="mh-75px mb-5"
                alt="branding"
              />
              <h1 className="fs-1x text-dark fw-bold">
                6. Customization and Branding
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Enables businesses to tailor the payment gateway's look and feel
                to match their brand identity, promoting brand consistency.
              </p>
            </div>
            <div className="col-12 col-md-6 mb-15">
              <img
                src={toAbsoluteUrl(
                  "/media/landing/merchant-invoice/notification.svg"
                )}
                className="mh-75px mb-5"
                alt="notification"
              />
              <h1 className="fs-1x text-dark fw-bold">
                7. Real-Time Notifications
              </h1>
              <p className="fs-3 text-dark mw-550px">
                Provides instant updates on payment status through callback URLs
                and webhooks for efficient order processing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <GradientBackground
        w100
        color={"00BAFF"}
        className={"px-5 pb-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">
              How to integrate OxaPay
              <br /> whitelabel in your business?
            </h1>
            <p className="fs-3 text-white mb-15">
              To start using OxaPay Merchant Whitelabel for your business, first
              sign up for an OxaPay account and get a merchant API key. Then,
              based on the programming language you're using for your project,
              send a request to{" "}
              <span
                className="bg-light px-3 rounded text-dark fs-4 fm-jetbrain"
                style={{ overflowWrap: "break-word" }}
              >
                https://api.oxapay.com/merchants/request/whitelabel
              </span>{" "}
              with the information you need. You can find sample code to help
              you and check out more details in the OxaPay documentation.
            </p>
            <a
              href="https://docs.oxapay.com/api-reference/creating-white-label-payment"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <AppButton className="btn-black px-14 py-5">
                Documentation
              </AppButton>
            </a>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <div className="d-flex flex-column overflow-hidden mt-20 mb-5 w-100">
              <MerchantInvoiceCode light />
            </div>
          </div>
        </div>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                Stay Updated with Payments
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                Get instant updates on your payments with our Webhooks service.
                Easily set up notifications to be alerted instantly about
                transaction updates directly to your system Just tell us where
                to send the info, and we'll keep you in the loop on every
                payment's progress. Whether a payment goes through, hits a snag,
                or changes status, you'll know right away. It's easy to set up
                and keeps your payment process smooth and worry-free. Ideal for
                any business looking for a hassle-free way to track payments.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl(
                  "media/landing/merchant-invoice/webhook.svg"
                )}
                className="w-100 mw-400px"
                alt="webapp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#CBE0D0" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/merchant-invoice/fiat.svg")}
                className="w-100 mw-550px"
                alt="fiat"
              />
            </div>
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                Choose Fiat as Your Invoice Currency
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                OxaPay Merchant API offers a dynamic invoicing solution,
                enabling businesses to create invoices in one of 40 different
                fiat currencies, such as USD, EUR, GBP, and JPY. This
                versatility allows businesses to tailor their billing to match
                the currency they operate in, ensuring a smooth and integrated
                financial management system. Customers, on the other hand,
                benefit from the flexibility to settle these invoices with the
                cryptocurrency of their choosing, making payments effortless and
                efficient. This dual convenience streamlines the entire payment
                process, fostering a more accessible global marketplace for
                businesses and their clients alike.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FAQSection
        about={"Merchant White-label"}
        faqs={oxapayCryptoWhitelabel}
        questionsSize={"fs-3"}
      />
    </>
  );
};
