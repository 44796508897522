import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";

export const MerchantMiddleware = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const trackId = searchParams.get("tgWebAppStartParam");
  useEffect(() => {
    navigate(`${trackId}?webapp=true`);
    // eslint-disable-next-line
  }, []);

  return <LoadingComponent />;
};
