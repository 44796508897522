import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { MerchantInvoiceCode } from "./MerchantInvoiceCode";

export const CreateInvoiceMain = () => {
  return (
    <div className="d-flex flex-column overflow-hidden">
      <MerchantInvoiceCode />
      <div className="row">
        <div className="col-12 col-md-7">
          <div className="bg-light-primary w-100 rounded-20px overflow-hidden mb-5">
            <img
              className="w-100"
              src={toAbsoluteUrl("/media/landing/main/invoice-preview.png")}
              alt="invoice"
              loading="lazy"
            />
          </div>
        </div>
        <div className="col-12 col-md-5 d-flex">
          <div className="hover-scale bg-light-primary w-100 rounded-20px px-2 py-10 d-flex flex-column position-relative mb-5">
            <a
              href="https://oxapay.com/pay/91905494"
              target={"_blank"}
              rel="noreferrer"
              aria-label="Pay Now with OxaPay"
            >
              <div className="w-70px h-70px position-absolute bg-white top-right symbol symbol-circle d-flex justify-content-center align-items-center rotate-45">
                <i className="fa-solid fa-arrow-right text-black fs-3x"></i>
              </div>
            </a>
            <div className="d-flex flex-grow-1 align-items-center">
              <a
                href="https://oxapay.com/pay/91905494"
                target={"_blank"}
                rel="noreferrer"
                aria-label="Invoice Example: To view a sample invoice on OxaPay, please click here."
              >
                <div className="px-10">
                  <i className="fa-solid fa-file-invoice text-black fs-4x mb-5"></i>
                  <h3>Invoice Example</h3>
                  <p className="text-gray-800">
                    To view a sample invoice on OxaPay, please click here.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
