import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Offline } from "react-detect-offline";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";

// Apps
import { MetronicI18nProvider } from "./_oxapay/i18n/Metronici18n";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_oxapay/assets/sass/plugins.scss";
import "./_oxapay/assets/sass/style.scss";
import "./_oxapay/assets/sass/style.react.scss";
import "./_oxapay/assets/css/custom.css";
import "./_oxapay/assets/css/main.css";
import "./_oxapay/assets/css/webapp.css";

import { AppRoutes } from "./app/routing/AppRoutes";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

const API_URL = process.env.REACT_APP_API_URL;
const POLLING_URL = `${API_URL}/core/ping`;

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://118c6c3f351851407ce0e3b37b4e3be8@o4506337447116800.ingest.sentry.io/4506337451966464",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <Offline
        polling={{
          enabled: true,
          interval: 20000,
          timeout: 10000,
          url: POLLING_URL,
        }}
        onChange={(online) => {
          online === false
            ? toast.info("No internet connection", {
                toastId: "networkError",
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                theme: "dark",
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                transition: Slide,
                icon: false,
              })
            : toast.dismiss();
        }}
      />
      <ToastContainer />
      <MetronicI18nProvider>
        <AppRoutes />
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
