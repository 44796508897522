import React, { useContext } from "react";
import { PaymentlinkContext } from "../../app/Core/PaymentLinkContext";
import { Check } from "../animatedicons/Check";
import { TextCopyWidget } from "../TextCopyWidget";

export const PaymentlinkStepFour = () => {
  const { paymentlinkSession } = useContext(PaymentlinkContext);
  return (
    <>
      <Check className="mt-20 mb-5" />
      <div className="mb-5 p-10">
        <p className="fs-3 fw-bold text-success mb-1">Successful Payment</p>
        <p className="text-gray-500 fw-semibold fs-6">
          You have payed the invoice successfully
        </p>
        {paymentlinkSession?.id && (
          <p className=" rounded px-2 d-block py-1 fs-7 fw-semibold text-dark mb-7 mt-0">
            Transaction Track ID:{" "}
            <TextCopyWidget
              text={`${paymentlinkSession.id}`}
              tooltipText="Copy Track ID"
              className="fs-5 mb-2 mt-7 fm-jetbrain"
            />
          </p>
        )}
      </div>
    </>
  );
};
