import React, { useContext, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { MerchantsContext } from "../app/Core/MerchantContext";
import { ShowAlertContext } from "../app/Core/ShowAlertContext";
import { ExpiredInvoice } from "../app/pages/ExpiredInvoice";
import { PaidInvoice } from "../app/pages/PaidInvoice";
import { MerchantWebappStepFour } from "./merchantWebapp/MerchantWebappStepFour";
import { MerchantWebappStepOneCoin } from "./merchantWebapp/MerchantWebappStepOneCoin";
import { MerchantWebAppStepOneNetwork } from "./merchantWebapp/MerchantWebAppStepOneNetwork";
import { MerchantWebappStepThree } from "./merchantWebapp/MerchantWebappStepThree";
import { MerchantWebappStepTwo } from "./merchantWebapp/MerchantWebappStepTwo";
import { PayPagesFooter } from "./PayPagesFooter";
import { TimeCounter } from "./TimeCounter";
import { WebappAlert } from "./WebappAlert";

export const MerchantWebapp = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { showAlert } = useContext(ShowAlertContext);
  const { merchantsSession, expired, expireAt } = useContext(MerchantsContext);
  const [url, setUrl] = useState<string | undefined>("");
  const [showStepNetworkOnBack, setShowStepNetworkOnBack] = useState(true);

  useEffect(() => {
    expired && setActiveIndex(5);
  }, [expired]);

  useEffect(() => {
    activeIndex < 3 &&
      expireAt &&
      expireAt < Math.floor(Date.now() / 1000) &&
      setActiveIndex(5);
  }, [expireAt, activeIndex]);

  useEffect(() => {
    setUrl(merchantsSession?.returnUrl);
  }, [merchantsSession?.returnUrl]);

  const backBtnFunc = () => {
    if (activeIndex === 2) {
      showStepNetworkOnBack
        ? setActiveIndex(activeIndex - 1)
        : setActiveIndex(activeIndex - 2);
    } else setActiveIndex(activeIndex - 1);
  };

  useEffect(() => {
    const Webapp = (window as any)?.Telegram?.WebApp;
    Webapp.expand();

    if (activeIndex === 1 || activeIndex === 2) {
      Webapp.BackButton.show();
      Webapp.onEvent("backButtonClicked", backBtnFunc);
    } else {
      Webapp.BackButton.hide();
    }

    if (activeIndex <= 2) {
      Webapp.enableClosingConfirmation();
    } else {
      Webapp.disableClosingConfirmation();
    }

    if (activeIndex === 4) {
      Webapp.MainButton.setText("Close")
        .setParams({ color: "#50cd89" })
        .show()
        .onClick(() => {
          Webapp.close();
        });
    } else {
      Webapp.MainButton.hide();
    }

    // eslint-disable-next-line
  }, [activeIndex, url]);

  return (
    <div className="d-flex flex-column flex-root bg-custom-white pb-20 font-ios justify-content-center">
      {showAlert && <WebappAlert />}
      <Carousel activeIndex={activeIndex} controls={false} indicators={false}>
        <Carousel.Item>
          <MerchantWebappStepOneCoin
            onSelect={(step) => {
              step === 2
                ? setShowStepNetworkOnBack(false)
                : setShowStepNetworkOnBack(true);
              setActiveIndex(step);
            }}
            setActiveIndex={(step) => setActiveIndex(step)}
          />
        </Carousel.Item>
        <Carousel.Item>
          <MerchantWebAppStepOneNetwork onSelect={() => setActiveIndex(2)} />
        </Carousel.Item>
        <Carousel.Item>
          <MerchantWebappStepTwo setActiveIndex={setActiveIndex} />
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <MerchantWebappStepThree
            activeStep={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <MerchantWebappStepFour />
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <ExpiredInvoice />
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <PaidInvoice />
        </Carousel.Item>
      </Carousel>

      <div
        className={`powered-footer z-index-3 ${
          !expired &&
          expireAt &&
          activeIndex < 3 &&
          "d-flex justify-content-center px-5"
        }`}
      >
        <PayPagesFooter tooltipPlacement="top" />
        {!expired && expireAt && activeIndex < 3 && (
          <p className="ms-6 fs-5 m-0">
            <span className="text-dark">Invoice expire at</span>{" "}
            <span className="text-primary fw-bold">
              <TimeCounter endtime={expireAt} />
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
