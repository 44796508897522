import React from 'react'

interface props {
  title?: string
}
export const WaitButtonSpan = ({title = 'Please wait...'}: props) => {
  return (
    <span className='indicator-progress' style={{display: 'block'}}>
      {title}
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )
}
