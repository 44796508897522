import React, { useEffect, useState } from "react";

interface Props {
  endtime: number;
}

export const TimeCounter = ({ endtime }: Props) => {
  const [remainingTime, setRemainingTime] = useState<number>();

  useEffect(() => {
    const updateTimer = () => {
      const timeDiff = endtime - Math.floor(Date.now() / 1000);
      if (timeDiff < 0) {
        clearInterval(interval);
      } else {
        setRemainingTime(timeDiff);
      }
    };

    const interval = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endtime]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${
      hours !== 0 ? formattedHours + ":" : ""
    }${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <>
      {remainingTime !== undefined ? (
        formatTime(Math.floor(remainingTime))
      ) : (
        <span className="h-10px w-40px bg-secondary rounded mr-ios-15 shine d-inline-block"></span>
      )}
    </>
  );
};
