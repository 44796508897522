export const loadTawkScript = () => {
  // Create a script element
  const s1 = document.createElement("script");

  // Find the first script element in the document
  const s0 = document.getElementsByTagName("script")[0];

  // Set attributes for the script element
  s1.async = true;
  s1.src = "https://embed.tawk.to/637cb5c2daff0e1306d8cb12/1gific0kh";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "anonymous"); // Changed "*"" to "anonymous" for better security

  // Insert the new script element before the first script element
  s0.parentNode?.insertBefore(s1, s0);
};
