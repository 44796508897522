import React from "react";

interface props {
  count?: number;
}
export const PricingSkeleton = ({ count = 4 }: props) => {
  return (
    <tr>
      <td className="py-5">
        <div className="d-flex flex-row align-items-center">
          <div className="h-50px w-50px bg-secondary rounded mr-ios-15 shine me-2"></div>
          <div className="h-30px w-100px bg-secondary rounded mr-ios-15 shine"></div>
        </div>
      </td>
      <td className="py-5">
        <div className="h-30px w-100px bg-secondary rounded mr-ios-15 shine"></div>
      </td>
      <td className="py-5">
        <div className="h-30px w-150px bg-secondary rounded mr-ios-15 shine"></div>
      </td>
      {count >= 4 && (
        <td className="py-5">
          <div className="h-30px w-150px bg-secondary rounded mr-ios-15 shine"></div>
        </td>
      )}
    </tr>
  );
};
