import { Carousel } from "react-bootstrap";
import { RotateBlock } from "./RotateBlock";

export const BlockConfirm = () => {
  return (
    <>
      <Carousel indicators={false} controls={false}>
        <Carousel.Item interval={2500}>
          <RotateBlock />
        </Carousel.Item>
        <Carousel.Item interval={2500}>
          <RotateBlock />
        </Carousel.Item>
      </Carousel>
    </>
  );
};
