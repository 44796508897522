import React from "react";
import { Link } from "react-router-dom";
import { AbsoluteCircle } from "./AbsoluteCircle";
import { AppButton } from "./AppButton";
import { PluginCard } from "./PluginCard";

export const PluginsSection = () => {
  return (
    <div className="px-5">
      <div className="p-10 d-flex flex-column justify-content-center align-items-center dark-gray-gradient w-100 py-20 rounded-35px card-height-primary overflow-hidden">
        <AbsoluteCircle position="top-left" />
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5 my-15">
            <h1 className="fs-4x text-white mb-10">
              OxaPay E-commerce plugins and apps
            </h1>
            <p className="text-white fs-3 mb-10 mw-550px">
              Integrate the cryptocurrency payment gateway into your E-Commerce
              platform quickly and easily using OxaPay plugins and applications,
              requiring just a few clicks.
            </p>
            <Link to={"plugins-list"}>
              <AppButton className="btn-black px-14 py-5">
                All Plugins
              </AppButton>
            </Link>
          </div>
          <div className="col-12 col-md-5">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 p-0">
                <PluginCard download={false} name="woocommerce" />
                <PluginCard download={false} name="clientexec" />
                <PluginCard download={false} name="easy-digital-downloads" />
              </div>
              <div className="col-12 col-md-6 p-0">
                <PluginCard download={false} name="blesta" />
                <PluginCard download={false} name="wisecp" />
                <PluginCard download={false} name="whmcs" />
                <PluginCard download={false} name="prestashop" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
