import React from "react";

export const WebappStepTwoSkeleton = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="h-35px w-150px bg-secondary rounded m-auto shine mb-4"></div>
      <div className="h-25px w-250px bg-secondary rounded m-auto shine mb-1"></div>
      <div className="h-25px w-300px bg-secondary rounded m-auto shine mb-1"></div>
      <div className="h-25px w-200px bg-secondary rounded m-auto shine mb-4"></div>
      <div className="h-150px w-150px bg-secondary rounded shine mt-12 mb-8"></div>
      <div className="h-40px w-300px bg-secondary rounded m-auto shine mb-1"></div>
      <div className="h-20px w-100px bg-secondary rounded m-auto shine mb-7"></div>
      <div className="h-40px w-150px bg-secondary rounded m-auto shine mb-1"></div>
      <div className="h-20px w-100px bg-secondary rounded m-auto shine"></div>
      <div className="p-10">
        <div className="d-flex align-items-center">
          <div className="circles mt-1 me-2">
            <div className="first circle"></div>
          </div>

          <div className="h-25px w-150px bg-secondary rounded m-auto shine"></div>
        </div>
      </div>
    </div>
  );
};
