import { Link } from "react-router-dom";
import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { coins } from "../../services/landingDatas";

export const AllCoins = () => {
  return (
    <>
      <GradientBackground
        color={"0027ee"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          Supported coins and
          <br />
          blockchains
        </p>
      </GradientBackground>

      <div className="px-5">
        <div
          className="justify-content-center align-items-center w-100 rounded-35px overflow-hidden py-15"
          style={{ background: "#DFDFE7" }}
        >
          <div className="container-fluid max-width-1550">
            <div className="my-15">
              <div className="m-auto" style={{ maxWidth: "1000px" }}>
                <h1 className="fs-4x text-dark text-center fw-bold">
                  All supported coins and blockchains
                </h1>
                <p className="fs-3 text-gray-700 mb-20">
                  Explore our updated list of accepted coins and blockchains
                  here. At OxaPay, we are constantly working to expand our
                  offerings. Your input matters to us, so please feel free to
                  share your suggestions for new coins and blockchains.
                </p>
              </div>

              <div className="row mw-800px m-auto">
                {Object.values(coins)
                  .filter((coin) => coin.status === true)
                  .map((coin, index) => (
                    <div className="col-4 col-md-2 my-3" key={index}>
                      <Link to={`/currencies/${coin.slug}`}>
                        <img
                          src={toAbsoluteUrl(
                            `media/svg/coins/${coin.slug}.svg?v=1`
                          )}
                          className="hover-scale h-80px h-lg-100px"
                          alt={`${coin.name} logo SVG OxaPay`}
                        />
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
