import React from "react";
import { useParams } from "react-router-dom";
import { Check } from "../animatedicons/Check";
import { TextCopyWidget } from "../TextCopyWidget";

export const MerchantWebappStepFour = () => {
  const { id } = useParams();
  return (
    <>
      <Check className="mt-20" />
      <div className="p-10">
        <p className="fs-ios-22 fw-bolder mb-ios-12">Successful Payment</p>
        <p className="fs-ios-17 mb-ios-46">
          You have paid the invoice successfully
        </p>
        <div>
          {id && (
            <p className=" rounded px-2 d-block py-1 fs-7 fw-semibold text-secondary-500 mb-0 mt-10">
              Transaction Track ID:{" "}
              <TextCopyWidget
                text={`${id}`}
                tooltipText="Copy Track ID"
                className="fs-5 mb-2 mt-7 fm-jetbrain"
              />
            </p>
          )}
        </div>
      </div>
    </>
  );
};
