import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

export const IconArrowIOS = () => {
  return (
    <img
      className="mr-ios-15"
      src={toAbsoluteUrl("/media/webapp/arrow-right.svg")}
      alt={"icon-ios-arrow"}
    />
  );
};
