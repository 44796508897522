import React from "react";
import { DonationContent } from "../../components/DonationContent";
import DonateProvider from "../Core/DonateContext";

export const Donation = () => {
  return (
    <DonateProvider>
      <DonationContent />
    </DonateProvider>
  );
};
