// CoinCarousel.jsx
import { Carousel } from "react-bootstrap";
import { coins } from "../../app/services/landingDatas";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

interface props {
  coinSize: string;
  coinsList: string[];
  activeIndex: number;
  [key: string]: any;
}

const CoinCarousel = ({ coinSize, coinsList, activeIndex, ...rest }: props) => {
  return (
    <Carousel
      activeIndex={activeIndex}
      controls={false}
      indicators={false}
      {...rest}
    >
      {coinsList.map((symbol, index) => (
        <Carousel.Item key={index}>
          <img
            src={toAbsoluteUrl(
              `media/landing/coins/${coins[symbol]?.slug}.png`
            )}
            className={`${coinSize} rotate-90-n`}
            alt={coins[symbol]?.name}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CoinCarousel;
