import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { SupportedCoins } from "../../../components/main/SupportedCoins";
import TypingEffect from "../../../components/main/TypingEffect";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { donationQuestions } from "../../services/faqs";

export const DonationPage = () => {
  return (
    <>
      <GradientBackground
        color={"895d20"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          Accept Crypto
          <br />
          Donates in your{" "}
          <TypingEffect textArray={["Business", "Website", "Apps"]} />
        </p>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0">
          OxaPay Donation Link
        </p>
      </GradientBackground>
      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#f9fafb" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                What is Donation link?
              </h1>
              <p className="fs-3 text-gray-800 mb-15">
                A donation link allows your visitors to easily donate
                cryptocurrencies to support your cause. Simplify the donation
                process by adding a button or link to your website, which you
                can use anywhere you want. Visitors can contribute using
                Bitcoin, Ethereum, or other cryptocurrencies. Don't forget to
                share your donation link on social media platforms such as
                Twitter and Instagram to reach even more supporters.
              </p>
              <h1 className="fs-3x text-dark fw-bold mb-7">
                How to create a donation link?
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                To make a crypto donation link, all you need is an OxaPay
                account. Go to the Donations section in your account, fill in
                details like the accepted coins, and other options. After that,
                you'll get a link and button code. Share this link with others
                or put it on your website and social media.
              </p>
              <a
                href="https://docs.oxapay.com/integrations/donations"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <AppButton className="btn-dark px-14 py-5">
                  Full tutorial
                </AppButton>
              </a>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/donation/donatepreview.png")}
                className="w-100 mw-600px my-10"
                alt="coin"
              />
            </div>
          </div>
        </div>
      </div>
      <GradientBackground
        color={"bc99b4"}
        className={"px-5"}
        decoratives={<AbsoluteCircle position="top-left" />}
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-5">
            <h1 className="fs-3x text-white fw-bold mb-7">
              Donation link or Button
            </h1>
            <p className="fs-3 text-white mb-15">
              Once you have successfully created a donation link, you can
              conveniently locate it within the donations list table. In
              addition, you have the option to obtain a specific HTML code
              associated with the link. This HTML code makes it effortless for
              you to seamlessly integrate the donation link into your website,
              allowing your visitors and supporters to contribute to your cause
              with utmost ease and convenience.
            </p>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <img
              src={toAbsoluteUrl("media/landing/donation/donatebuttons.png")}
              className="w-100 mw-600px my-10"
              alt="coin"
            />
          </div>
        </div>
      </GradientBackground>
      <SupportedCoins title="Accept +10 coins and Tokens as Donation" />
      <FAQSection
        about={"Donation link"}
        faqs={donationQuestions}
        questionsSize={"fs-3"}
      />
    </>
  );
};
