import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { Check } from "../animatedicons/Check";
import { TextCopyWidget } from "../TextCopyWidget";

export const MerchantStepFour = () => {
  const { merchantsSession } = useContext(MerchantsContext);
  const { id } = useParams();
  return (
    <>
      <Check className="mt-20 mb-5" />
      <div className="mb-10 p-10">
        <p className="fs-3 fw-bold text-success mb-1">Successful Payment</p>
        <p className="text-gray-500 fw-semibold fs-6">
          You have payed the invoice successfully
        </p>
        {id && (
          <p className=" rounded px-2 d-block py-1 fs-7 fw-semibold text-dark mb-7 mt-0">
            Transaction Track ID:{" "}
            <TextCopyWidget
              text={`${id}`}
              tooltipText="Copy Track ID"
              className="fs-5 mb-2 mt-7 fm-jetbrain"
            />
          </p>
        )}
        {merchantsSession?.returnUrl && (
          <a
            href={merchantsSession.returnUrl}
            className="btn btn-success w-200px"
          >
            Return to website
          </a>
        )}
      </div>
    </>
  );
};
