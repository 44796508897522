import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { Footer } from "../../../components/main/Footer";
import { NavBar } from "../../../components/main/Navbar";
import { PolicyFooter } from "../../../components/PolicyFooter";
import { TelegramChatWidget } from "../../../components/TelegramChatWidget";
import { PrivacyPolicy } from "../../../_oxapay/partials/modals/privacypolicy";
import { Terms } from "../../../_oxapay/partials/modals/terms";
import { loadTawkScript } from "../../services/loadTawk";

export const LandingLayout = () => {
  const cookies = new Cookies();

  const [showPolicy, setShowPolicy] = useState(false);
  const [showTelegramWidget, setShowTelegramWidget] = useState(true);

  useEffect(() => {
    loadTawkScript();
    (window as any).Tawk_API = (window as any).Tawk_API || {};
    (window as any).Tawk_API.onStatusChange = function (status: any) {
      if (status === "offline") {
        setShowTelegramWidget(true);
        (window as any)?.Tawk_API.hideWidget();
      } else {
        setShowTelegramWidget(false);
        (window as any)?.Tawk_API.showWidget();
      }
    };

    const cookieAccepted = cookies.get("cookie-accepted");
    if (!cookieAccepted) {
      setShowPolicy(true);
    }
    const cookieReferrer = cookies.get("referrer");
    const referrer = document.referrer;
    referrer &&
      !cookieReferrer &&
      cookies.set("referrer", referrer, { domain: ".oxapay.com", path: "/" });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
      {showPolicy && (
        <>
          <PolicyFooter
            onClick={() => {
              setShowPolicy(false);
            }}
          />

          <PrivacyPolicy />
        </>
      )}
      {showTelegramWidget && <TelegramChatWidget />}
      <Terms />
    </div>
  );
};
