import { coinsPageData, pluginsPageData } from "./landingDatas";

// Helper function to get the page title based on the current route
export const getPageTitle = (pathname: string) => {
  switch (true) {
    case pathname === "/":
      return "Accept Crypto In your Business";
    case pathname.startsWith("/pay/"):
      return "Pay Invoice";
    case pathname.startsWith("/donate/"):
      return "Donate";
    case pathname.startsWith("/mpay/"):
      return "Invoice";
    case pathname.startsWith("/wallet"):
      return "Wallet";
    case pathname.startsWith("/payment-link"):
      return "Payment link";
    case pathname.startsWith("/donations"):
      return "Accept Crypto Donates";
    case pathname.startsWith("/swap"):
      return "Swap";
    case pathname.startsWith("/merchant-api"):
      return "Merchant API";
    case pathname.startsWith("/payout-api"):
      return "Payout API";
    case pathname.startsWith("/telegram-channel-gateway"):
      return "Telegram Channel Gateway";
    case pathname.startsWith("/referrals-program"):
      return "Referrals Program";
    case pathname.startsWith("/merchant-crypto-invoice"):
      return "Crypto Invoice";
    case pathname.startsWith("/merchant-whitelabel"):
      return "Whitelabel Invoice";
    case pathname.startsWith("/currencies"):
      if (pathname === "/currencies") {
        return "Supported Coins & Networks";
      } else {
        const coinName = pathname.split("/")[2];
        return `Accept ${
          coinName
            ? coinsPageData[coinName].name +
              " (" +
              coinsPageData[coinName].symbol +
              ")"
            : ""
        }`;
      }
    case pathname.startsWith("/plugins"):
      if (pathname === "/plugins") {
        return "Plugins";
      } else {
        const pluginName = pathname.split("/")[2];
        return `Accept Crypto in ${
          pluginName ? pluginsPageData[pluginName].name : ""
        }`;
      }
    default:
      return "Accept Crypto In your Business";
  }
};
