import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { AppButton } from "../../../components/main/AppButton";
import { FAQSection } from "../../../components/main/FAQSection";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { PayoutAPICode } from "../../../components/main/PayoutAPICode";
import { SupportedCoins } from "../../../components/main/SupportedCoins";
import { toAbsoluteUrl } from "../../../_oxapay/helpers";
import { payoutAPIQuestions } from "../../services/faqs";

export const PayoutAPI = () => {
  return (
    <>
      <GradientBackground
        color={"d34080"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5 mw-1000px">
          Auto transfer with Zero fee by Payout API
        </p>
        <p className="fs-md-1 fs-5 text-center text-secondary m-0">
          have micropayment to others
        </p>
      </GradientBackground>

      <div className="px-5 pb-5">
        <div
          className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden"
          style={{ background: "#e8e8e8" }}
        >
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                What is Payout API?
              </h1>
              <p className="fs-3 text-gray-800 mb-15">
                Utilize the OxaPay payout API to effortlessly conduct transfers
                to other users automatically. This feature is especially useful
                for webmasters dealing with micropayments to their users. Simply
                create an Payout API and refer to our detailed documentation to
                get started seamlessly.
              </p>
              <h1 className="fs-3x text-dark fw-bold mb-7">
                How to transfer crypto with zero fee?
              </h1>
              <p className="fs-3 text-gray-800 mb-10">
                No fees apply to your transfers if the recipient's address is
                within OxaPay or is an OxaPay address. Enjoy fee-free
                transactions, making your payouts hassle-free and
                cost-effective.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <img
                src={toAbsoluteUrl("media/landing/payout/payout.svg")}
                className="w-100 mw-600px my-10"
                alt="coin"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5">
        <div className="p-10 d-flex flex-column justify-content-center align-items-center w-100 py-20 rounded-35px card-height-primary overflow-hidden">
          <div className="row w-100 justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h1 className="fs-3x text-dark fw-bold mb-7">
                How to have auto transfer to others with Payout API?
              </h1>
              <p className="fs-3 text-gray-800 mb-15">
                This resource allows users to submit cryptocurrency payouts to
                active recipients. It caters to various use cases such as
                offering cryptocurrency withdrawals to clients, facilitating
                payouts for marketplaces or affiliate networks, or managing
                payroll by creating multiple payouts at a time.
              </p>
              <a
                href="https://docs.oxapay.com/api-reference/creating-payout"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <AppButton className="btn-dark px-14 py-5">
                  Documentation
                </AppButton>
              </a>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <div className="d-flex flex-column overflow-hidden mt-20 mb-5 w-100 mw-600px">
                <PayoutAPICode />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportedCoins title="Transfer +10 coins and Tokens with Zero fee" />
      <FAQSection
        about={"Payout API"}
        faqs={payoutAPIQuestions}
        questionsSize={"fs-3"}
      />
    </>
  );
};
